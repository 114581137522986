import { cva, VariantProps } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { convertDate } from '../../../shared/utils/convert-date';
import { IFile } from '../../../widgets/application/types';
import FileButton from '../../../entities/file/ui';
import s from './index.module.scss';
import { TDocFetch, TDocFetchWithUser } from '../../../../shared/types/common.types';
import { children } from 'cesium';

type Props = {
  text: string | ReactNode;
  files?: TDocFetchWithUser[];
  date?: string | Date;
} & VariantProps<typeof cvaMessage>;

const cvaFont = cva([s.font]);

const cvaMessage = cva([s.message], {
  variants: {
    serverTextVariant: {
      green: [cvaFont(), s.green],
      red: [cvaFont(), s.red],
      orange: [cvaFont(), s.orange],
      main: [cvaFont()],
    },
    isFull: {
      true: [s.full],
    },
    isUserMessage: {
      true: [s.isUserMessage],
      false: [s.isntUserMessage],
    },
  },
});

const cvaFiles = cva([], {
  variants: {
    isUserMessage: {
      true: [s.isUserFile],
      false: [s.isntUserFile],
    },
  },
});

const cvaTime = cva([s.time]);

export default function Message({ text, files, isUserMessage = false, serverTextVariant, isFull, date }: Props) {
  return (
    <>
      {text && (
        <div className={cvaMessage({ isUserMessage, serverTextVariant, isFull })}>
          {typeof text == 'string' ? <div dangerouslySetInnerHTML={{ __html: text }}></div> : <div>{text}</div>}
          {date && <div className={cvaTime()}>{convertDate(date, { onlyTime: true })}</div>}
        </div>
      )}
      {files && files.length > 0 && (
        <div className={cvaFiles({ isUserMessage })}>
          {files.map((file) => (
            <FileButton key={file.id} file={file} />
          ))}
        </div>
      )}
    </>
  );
}
