import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import userReducer from 'manageStore/user/userSlice';
import designerReducer from 'manageStore/designer/designer.slice';
import newsReducer from 'manageStore/news/newsSlice';
import placesReducer from 'manageStore/places/placesSlice';
import toursReducer from 'manageStore/tours/tours.slice';
import partnersReduser from 'manageStore/partners/partners.slice';
import travelsGuideReduser from 'manageStore/travelsGuide/travelsGuide.slice';
import commonReducer from "./manageStore/common/common.slice";
import { reviewsReducer } from 'manageStore/reviews/reviews.slice';
import { requestReduser } from 'manageStore/request/request.slice';
import { touristReducer } from 'manageStore/tourist/tourist.slice';
import { cesiumReducer } from 'manageStore/cesium/cesium.slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const designerPersistConfig = {
  key: 'designer',
  storage,
  whitelist: ['order'],
};

const rootReducer = combineReducers({
  user: userReducer,
  designer: persistReducer(designerPersistConfig, designerReducer),
  news: newsReducer,
  places: placesReducer,
  tours: toursReducer,
  reviews: reviewsReducer,
  request: requestReduser,
  tourist: touristReducer,
  cesium: cesiumReducer,
  partners: partnersReduser,
  travelsGuide: travelsGuideReduser,
  common: commonReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof store.getState>;
