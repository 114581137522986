import { ReactComponent as ChartIcon } from 'assets/images/trk/trk-chart.svg';
import { ReactComponent as JusticeIcon } from 'assets/images/trk/trk-justice.svg';
import { TPrivilegeItem } from '../../../../../shared/types/aboutUs.types';
import { useMediaQuery } from '../../../../../shared/hooks/useMatchMedia';
import { PrivilegesSectionMobile } from './PrivilegesSectionMobile';
import { PrivilegesSectionDesktop } from './PrivilegesSectionDesktop';

const privilegeItems: TPrivilegeItem[] = [
  {
    id: 'chart',
    label: 'Развитие и продвижение вашего бизнеса',
    icon: <ChartIcon className='privileges-card__icon' />,
  },
  {
    id: 'justice',
    label: 'Льготные или бесплатные консалтинговые и бизнес-услуги',
    icon: <JusticeIcon className='privileges-card__icon' />,
  },
];

/*
 * TODO: Change these links
 * */
const privilegeDocUrl = '/docs/Регламент_Мой бизнес _услуги ЦКР.pdf';
const clusterDevelopmentCenter = 'https://xn---24-9cdulgg0aog6b.xn--p1ai/sections/centr-klasternogo-razvitiya/';
const agreements96Doc = '/docs/Соглашение_№96_22_от_03_06_2022_АНО_ККЦРБ_МКК.pdf';

export const PrivilegesSection = () => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  return (
    <section className='container privileges-content__second'>
      <h1 className='privileges-content__title'>Привилегии для участников <br/> ТРК "Арктический"</h1>
      <p className='privileges-content__desc'>
        Участники Кластера получают следующую поддержку от Центра кластерного развития
      </p>

      {isMobile ? (
        <PrivilegesSectionMobile privilegeItems={privilegeItems} />
      ) : (
        <PrivilegesSectionDesktop privilegeItems={privilegeItems} />
      )}

      <div className='privileges-content__footer'>
        <div className='privileges-content__footer__description'>
          <p>Полный перечень привилегий указан <a href={privilegeDocUrl} target='_blank' rel='noreferrer'>здесь</a> и на <a href={clusterDevelopmentCenter} target='_blank'>сайте Центра кластерного развития.</a></p>

        </div>
        <div className='privileges-content__footer__description'>
          <p>
            Услуги предоставляются на основании{' '}
            <a href={agreements96Doc} target='_blank' rel='noreferrer'>
              Соглашения №96-22 от 03.06.2022 г.
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
