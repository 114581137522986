import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuth } from 'manageStore/user/userSlice';
import { Portal } from 'shared/lib/Portal/Portal';
import { TourApplicationForm } from './TourApplicationForm/TourApplicationForm';
import { TourDesignerPopup } from 'features/shared/TourDesignerPopup/TourDesignerPopup';
import { TTour } from 'shared/types/tours.types';

type TApplicationReadyTour = {
  openPortal: boolean;
  handlePortal: () => void;
  activeTour: TTour;
};

export const TourReadyApplication = ({ openPortal, handlePortal, activeTour }: TApplicationReadyTour) => {
  const history = useHistory();
  const isAuth = useSelector(selectIsAuth);

  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess((prev) => !prev);

  const annotationPopup = !isAuth
    ? 'Оставьте ваши контакты, наши операторы свяжутся с вами в ближайшее время.'
    : 'Вы готовы оставить заявку?';

  useEffect(() => {
    if (success && isAuth) {
      history.push('/account');
    }
  }, [success, isAuth]);

  return (
    <Portal isOpen={openPortal} onClose={handlePortal}>
      <TourDesignerPopup
        title={!success ? 'Заказать путешествие' : 'Заявка успешно отправлена!'}
        onClose={handlePortal}
        annotation={annotationPopup}
        className='tour-application ready'
      >
        <TourApplicationForm handleSuccess={handleSuccess} onClose={handlePortal} tour={activeTour} tourType='Ready' />
      </TourDesignerPopup>
    </Portal>
  );
};
