import { FunctionField, RaRecord, Show, SimpleShowLayout, TextField } from 'react-admin';
import { LOCATIONS3D_FIELDS } from 'shared/constants/const';
import CesiumMap from 'components/CesiumMap/CesiumMap';

type TRecord = RaRecord & { cesiumId: number };

const Locations3dShow = () => {
  return (
    <div style={{ marginBottom: '50px' }}>
      <Show>
        <SimpleShowLayout>
          <TextField source='name' label={LOCATIONS3D_FIELDS.title} />
          <FunctionField
            render={(record: TRecord) => (
              <div style={{ width: '100%', height: '500px' }}>
                <CesiumMap idTileset={record?.cesiumId} isOnce />
              </div>
            )}
          />
        </SimpleShowLayout>
      </Show>
    </div>
  );
};

export default Locations3dShow;
