import {TBlog, TNew} from "../../shared/types/types";
import {TERRITORY_ENUM} from "../../shared/constants/travelGuide.constants";

export type TExtendNews = TNew & {
    territoryTag:	TERRITORY_ENUM;
    typeTags: string[]
}

export const convertBlogToNews = (val:TBlog) :TExtendNews=>{
    let res:TExtendNews = {
        id:val.id,
        title: val.name,
        desc: val.metaDesc,
        content: "",
        important: val.isImportant,
        isPublished: true,
        date: val.createdDate,
        views: val.views,
        metaDesc: val.metaDesc,
        metaKeywords: val.metaKeywords,
        preview: val.preview,
        author:  null,
        territoryTag: val.territoryTag,
        typeTags: val.typeTags

    }
    return res
}
