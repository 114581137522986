import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { handleError } from 'http/handleError';
import Notification from 'shared/lib/notification';
import TravelGuideService from 'services/travelGuide.service';
import { TravelGuideItemIntro } from 'widgets/lib/travelGuideItemMain/TravelGuideItemIntro/TravelGuideItemIntro';
import { TTravelGuide } from 'shared/types/travelGuide.types';
import Loading from 'components/Loading/Loading';
import { TravelGuideItemDescription } from 'widgets/lib/travelGuideItemMain/TravelGuideItemDescription/TravelGuideItemDescription';
import { TravelGuideCityParams } from 'widgets/lib/travelGuideItemMain/TravelGuideItemParams/TravelGuideCityParams';
import { TravelGuideFloraParams } from 'widgets/lib/travelGuideItemMain/TravelGuideItemParams/TravelGuideFloraParams';
import { TravelGuideItemHistory } from 'widgets/lib/travelGuideItemMain/TravelGuideItemHistory/TravelGuideItemHistory';
import { TravelGuideItemFacts } from 'widgets/lib/travelGuideItemMain/TravelGuideItemFacts/TravelGuideItemFacts';
import { TravelGuideItemAdvantage } from 'widgets/lib/travelGuideItemMain/TravelGuideItemAdvantage/TravelGuideItemAdvantage';
import { TravelGuideItemDigits } from 'widgets/lib/travelGuideItemMain/TravelGuideItemDigits/TravelGuideItemDigits';
import { TravelGuideItemFaces } from 'widgets/lib/travelGuideItemMain/TravelGuideItemFaces/TravelGuideItemFaces';
import { TravelGuideItemVideo } from 'widgets/lib/travelGuideItemMain/TravelGuideItemVideo/TravelGuideItemVideo';
import { TravelGuideItemMap } from 'widgets/lib/travelGuideItemMain/TravelGuideItemMap/TravelGuideItemMap';
import { TravelGuideItemSimilarPlaces } from 'widgets/lib/travelGuideItemMain/TravelGuideItemSimilarPlaces/TravelGuideItemSimilarPlaces';
import { TravelGuideItemGallery } from 'widgets/lib/travelGuideItemMain/TravelGuideItemGallery/TravelGuideItemGallery';
import { TravelGuideItemBlog } from 'widgets/lib/travelGuideItemMain/TravelGuideItemBlog/travelGuideItemBlog';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TravelGuideItemTours } from '../TravelGuideItemTours/TravelGuideItemTours';

export const TravelGuideItemMain = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const [travelGuide, setTravelGuide] = useState<TTravelGuide | null>(null);
  const [isLoading, setLoading] = useState(true);

  const previewUrl = `${URL_FOR_IMG}/${travelGuide?.photo.path ? travelGuide.photo.path : null}`.replaceAll(' ', '%20');

  const isCity = travelGuide?.type === 'Городская локация';

  const advantageAfterColor =
    isCity && (travelGuide.facts || travelGuide.faces?.length) ? 'beige' : travelGuide?.video ? 'black' : 'blue';

  const mapBackColor =
    isCity && travelGuide.video
      ? 'black'
      : isCity && (travelGuide.facts || travelGuide.faces?.length)
      ? 'beige'
      : 'blue';

  const isRectangleBeforeVideo = isCity && (!!travelGuide.facts || !!travelGuide.faces?.length);

  const mapAfterColor =
    isCity && travelGuide.tours?.length ? 'blue' : !isCity && travelGuide?.video ? 'black' : 'white';

  useEffect(() => {
    if (params.id) {
      const fetchTour = async () => {
        try {
          const { data } = await TravelGuideService.getTravelGuideById(params.id);
          setTravelGuide(data);
        } catch (e) {
          const errorMessage = handleError(e);
          Notification.error(errorMessage);
          throw e;
        } finally {
          setLoading(false);
        }
      };
      fetchTour();
    }
  }, [params]);

  useEffect(() => {
    if (!isLoading && !travelGuide) {
      history.push('/travel-guides');
    }
  }, [travelGuide, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  if (!travelGuide) {
    return null;
  }

  return (
    <main className='travel-guide-item'>
      <TravelGuideItemIntro
        title={travelGuide.name}
        tag={travelGuide.type}
        locationTags={travelGuide.locationTags}
        territoryTag={travelGuide.territoryTag}
        backImage={previewUrl}
        className={travelGuide.type}
      />
      <TravelGuideItemDescription description={travelGuide.description} className={isCity ? 'city' : 'flora'}>
        {isCity ? (
          <>
            <TravelGuideCityParams
              params={travelGuide.attributes}
              address={travelGuide.address}
              howToReach={travelGuide.howToReach}
            />
          </>
        ) : (
          <TravelGuideFloraParams
            params={travelGuide.attributes}
            transport={travelGuide.transport}
            nearCity={travelGuide.theNearestTown}
            coords={travelGuide.coords}
          />
        )}
      </TravelGuideItemDescription>
      {isCity && !!travelGuide.history?.length && <TravelGuideItemHistory history={travelGuide.history} />}
      {isCity && <TravelGuideItemFacts facts={travelGuide.facts} />}
      {isCity && <TravelGuideItemAdvantage advantages={travelGuide.reasons} afterColor={advantageAfterColor} />}
      {isCity && (!!travelGuide.factDescription || !!travelGuide.factDescription || travelGuide.factPhoto) && (
        <TravelGuideItemDigits
          description={travelGuide.factDescription}
          title={travelGuide.factTitle}
          photo={travelGuide.factPhoto}
        />
      )}
      {isCity && !!travelGuide.faces?.length && <TravelGuideItemFaces faces={travelGuide.faces} />}
      {isCity && !!travelGuide.video && (
        <TravelGuideItemVideo link={travelGuide.video} isRectangleBefore={isRectangleBeforeVideo} />
      )}

      {!isCity && <TravelGuideItemGallery photos={travelGuide.gallery} />}
      {!isCity && !!travelGuide.fauna?.length && <TravelGuideItemBlog places={travelGuide.fauna} isFauna />}
      {!isCity && !!travelGuide.flora?.length && <TravelGuideItemBlog places={travelGuide.flora} />}

      <TravelGuideItemMap
        location={travelGuide.locations}
        backColor={mapBackColor}
        afterColor={mapAfterColor}
        isCity={isCity}
      />
      {!isCity && !!travelGuide.video && <TravelGuideItemVideo link={travelGuide.video} isRectangleAfter />}

      {!!travelGuide.tours?.length && <TravelGuideItemTours tours={travelGuide.tours} isCity={isCity} />}
      {!!travelGuide.related?.length && <TravelGuideItemSimilarPlaces places={travelGuide.related} />}
    </main>
  );
};
