import axios from 'axios';
import api from 'http/commonApi';
import { TToken } from 'shared/types/cesiumMap.types';

export default class CesiumMapService {
  static async getTokens(token: TToken) {
    return axios.get('https://api.cesium.com/v2/tokens/', {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });
  }
  static async getAssets(token: TToken) {
    return axios.get(`https://api.cesium.com/v2/tokens/${token.id}`, {
      headers: { Authorization: `Bearer ${token.value}` },
    });
  }
  static async getTilesetsData() {
    return api().get('/structure');
  }
}
