import { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { TSingleFile } from 'shared/types/common.types';
import { URL_FOR_IMG } from 'shared/constants/const';
import { useClientRect } from 'shared/hooks/useClientRect';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import Portal from 'components/Portal/Portal';
import { useSwipe } from 'shared/hooks/useSwipe';

const settingsBaseSlider: Settings = {
  dots: false,
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right dark />,
  prevArrow: <ButtonSlider left dark />,
};

const settingsPortalSlider: Settings = {
  ...settingsBaseSlider,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

export const TourItemGallery = ({ photos }: { photos: TSingleFile[] | null }) => {
  const [paddingSlider, setPaddingSlider] = useState(100);

  const [initialSlide, setInitialSlide] = useState<number | undefined>();

  const [sectionRect, sectionRef] = useClientRect();
  const [startSwipe, endSwipe, isSwipe] = useSwipe();

  useEffect(() => {
    if (!sectionRect?.width) return;
    const beetwenPadding = window.innerWidth < 1124 ? -8 : 20;
    const centerPadding = Math.max((sectionRect.width - 1200) / 2 - beetwenPadding, Math.abs(beetwenPadding * 3));
    setPaddingSlider(centerPadding);
  }, [sectionRect]);

  if (!photos) {
    return null;
  }

  const closePortal = () => {
    setInitialSlide(undefined);
  };

  const setPortal = (index: number) => {
    setInitialSlide(index);
  };

  return (
    <div className='tour-photos' ref={sectionRef}>
      <Slider
        {...settingsBaseSlider}
        className='tour-photos__slider'
        centerPadding={`${paddingSlider}px`}
        swipeEvent={startSwipe}
      >
        {photos.map((photo, index) => {
          return (
            <div className='tour-photos__slider__item' key={photo.id}>
              <img
                src={`${URL_FOR_IMG}/${photo.path}`}
                alt='slide'
                onMouseDown={endSwipe}
                onClick={() => !isSwipe && setPortal(index)}
              />
            </div>
          );
        })}
      </Slider>
      <Portal isOpen={initialSlide !== undefined} onClose={closePortal} styles={{}} slider>
        <Slider
          className='tour-photos__slider tour-photos__slider_portal'
          {...settingsPortalSlider}
          centerPadding={`${paddingSlider}px`}
          initialSlide={initialSlide}
        >
          {photos.map((item) => (
            <div key={item.id} className='tour-photos__slider__item tour-photos__slider_portal__item'>
              <div className='tour-photos__slider_portal__content'>
                <button className='portal-close portal-close_slider' onClick={closePortal} />
                <img src={`${URL_FOR_IMG}/${item.path}`} alt='slider' />
              </div>
            </div>
          ))}
        </Slider>
      </Portal>
    </div>
  );
};
