import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import Portal from '../components/Portal/Portal';
import { useDispatch } from 'react-redux';
import LoginForm from '../features/lib/AuthUser/LoginForm';
import logo from '../assets/images/header/logo_top.svg';

type TLoginPageProps = {
  isAdmin: boolean;
};

const LoginPage = ({ isAdmin }: TLoginPageProps) => {
  const dispatch = useDispatch();

  const isAuth = useAppSelector((state) => state.user.isAuth);
  const userData = useAppSelector((state) => state.user.userData);
  const history = useHistory();
  const handelPortal = useCallback(() => {
    history.push('/')
  }, [dispatch]);

  useEffect(() => {
    if (isAuth && userData && Object.keys(userData).length) history.push('/account');
  }, [isAuth, userData, history]);

  return (
    <>
      <Helmet>
        <title>Вход - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <section className='content login-page-bg'>
        <div className='login-page__background'>
          <div className='login-page__background__image'>
            <Link className='login-page__background__logo' to='/'>
              <img className='logo' src={logo} alt='Туркластер Арктический' />
            </Link>
            <div className='login-page__background__label'>ПУТОРАНА</div>
          </div>
        </div>
        <Portal
          isOpen={true}
          onClose={handelPortal}
          styles={{
            padding: '20px',
            maxWidth: '500px',
            border: '1px solid #dedede',
          }}
          title='Авторизация'
        >
          <LoginForm isAdmin={isAdmin} />
        </Portal>

        <MobileBottom opacity />
      </section>
    </>
  );
};

export default LoginPage;
