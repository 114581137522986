import olen from 'assets/images/homePage/fauna/olen.png';
import morj from 'assets/images/homePage/fauna/morj.png';
import ovcebyk from 'assets/images/homePage/fauna/ovcebyk.png';
import pesec from 'assets/images/homePage/fauna/pesec.png';
import { OpacityContentCard } from 'shared/lib/OpacityContentCard/OpacityContentCard';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { Link } from 'react-router-dom';

type TCardContentProps = {
  title: string;
  link: string;
};

const CardContent = ({ title, link }: TCardContentProps) => {
  return (
    <>
      <TitleSectionMain title={title} className='home-fauna__card__title' />
      <Link className='home-fauna__card__btn' to={{ pathname: link }}>
        Читать
      </Link>
    </>
  );
};

export const HomeFauna = () => {
  return (
    <div className='home-fauna'>
      <div className='container home-fauna__container'>
        <TitleSectionMain
          className='home-fauna__title'
          title='Уникальный животный мир'
          subtitle='Животный мир Таймыра представлен различными видами зверей. Здесь можно встретить горностая, росомаху, соболя, песца, а на морском побережье — белого медведя. Таймыр — место обитания уникальных птиц — гагары, баклана, полярной совы, сокола и других!'
        />
        <div className='home-fauna__cards-list'>
          <OpacityContentCard className='home-fauna__card' srcImage={pesec} altImage='Забавный песец'>
            <CardContent title='Забавный песец' link='blog/21' />
          </OpacityContentCard>
          <OpacityContentCard className='home-fauna__card' srcImage={olen} altImage='Благородный северный олень'>
            <CardContent title='Благородный северный олень' link='blog/13' />
          </OpacityContentCard>
          <OpacityContentCard className='home-fauna__card' srcImage={ovcebyk} altImage='Величественный овцебык'>
            <CardContent title='Величественный овцебык' link='blog/20' />
          </OpacityContentCard>
          <OpacityContentCard className='home-fauna__card' srcImage={morj} altImage='Добродушый морж'>
            <CardContent title='Добродушый морж' link='blog/12' />
          </OpacityContentCard>
        </div>
      </div>
    </div>
  );
};
