import { useEffect, useState } from 'react';
import { URL_FOR_IMG } from 'shared/constants/const';
import { TUser } from 'shared/types/types';
import { declensionNoun } from 'helpers/declensionNoun';
import { selectCurrentRequest, selectMessageList } from 'manageStore/request/request.select';
import { useAppSelector } from 'shared/hooks/useAppSelector';

type TChatHeader = {
  toggleFilesharing: () => void;
};

export const ChatHeader = ({ toggleFilesharing }: TChatHeader) => {
  const userData = useAppSelector((state) => state.user.userData);
  const request = selectCurrentRequest();
  const messages = selectMessageList();
  const lastMessage = messages && messages.length? messages[messages.length - 1]?.time : '';

  const [chatUser, setChatUser] = useState<TUser | null>(null);

  useEffect(() => {
    if (request?.tourist && request?.operator) {
      if (request.tourist.id === userData?.id) setChatUser(request.operator);
      if (request.operator.id === userData?.id) setChatUser(request.tourist);
    }
  }, [request, userData]);

  return (
    <div className='chat-header'>
      <div className='chat-header__user-photo'>
        {chatUser?.photo?.path ? (
          <img src={`${URL_FOR_IMG}/${chatUser.photo.path}`} alt='Фото' />
        ) : (
          <div>{chatUser?.login[0] || ''}</div>
        )}
      </div>
      <p className='chat-header__text login bold'>{chatUser ? chatUser.login : null}</p>
      <p className='chat-header__text email'>
        {chatUser ? chatUser.email : null}{' '}
        {request?.operator.id === userData?.id && chatUser?.phone ? chatUser.phone : null}
      </p>
      <p className='chat-header__text name bold'>{request?.tour?.name || 'Тур из конструктора туров'}</p>
      <p className='chat-header__text last-message'>{lastMessage ? new Date(lastMessage).toLocaleString() : null}</p>
      <p className='chat-header__text group-size bold'>
        Размер группы:
        <span>
          {request?.tour?.groupSize
            ? `${request.tour.groupSize} ${declensionNoun(request.tour.groupSize, 'man')}`
            : '-'}
        </span>
      </p>
      <p className='chat-header__text date-start bold'>
        Дата начала:
        <span>{request?.tour?.dateStart ? new Date(request.tour.dateStart).toLocaleDateString() : '-'}</span>
      </p>
      <p className='chat-header__text duration bold'>
        Длительность:
        <span>
          {request?.tour?.duration ? `${request.tour.duration} ${declensionNoun(request.tour.duration, 'day')}` : '-'}
        </span>
      </p>
      <p className='chat-header__text price bold'>
        Стоимость:
        <span>{request?.tour?.price ? request.tour.price : '-'}</span>
      </p>
      <div className='chat-filesharing__toggle chat-filesharing__toggle_mobile' onClick={toggleFilesharing}></div>
    </div>
  );
};
