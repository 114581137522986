import { Edit } from 'react-admin';
import Locations3dForm from './Locations3dForm';

const Locations3dEdit = () => (
  <Edit>
    <Locations3dForm />
  </Edit>
);

export default Locations3dEdit;
