import api from 'http/commonApi';
import {TBlog} from 'shared/types/types';


export default class BlogService {
  static async getBlogById(id:number) {
    return api().get<TBlog>(`/blog/published/${id}`);
  }

  static async getBlogs() {
    return api().get<TBlog[]>(`/blog/published`);
  }

  static async addViewBlog(id:number) {
    return api().put<TBlog[]>(`/blog/${id}/views`);
  }

}
