import { TRANSFORM_TEXT } from 'shared/constants/const';
import { TFiltersTours } from 'shared/types/tours.types';
import { ReactComponent as CardsIcon } from 'assets/images/tours/cards.svg';
import { ReactComponent as FishIcon } from 'assets/images/tours/fish.svg';
import { ReactComponent as BusIcon } from 'assets/images/tours/bus.svg';
import { ReactComponent as ShoesIcon } from 'assets/images/tours/shoes.svg';
import { ReactComponent as HelicopterIcon } from 'assets/images/tours/helicopter.svg';
import { ReactComponent as EthnicIcon } from 'assets/images/tours/ethnic.svg';
import { ReactComponent as CampingIcon } from 'assets/images/tours/camping.svg';
import { ReactComponent as SnowmobileIcon } from 'assets/images/tours/snowmobile.svg';
import { ReactComponent as JeepIcon } from 'assets/images/tours/jeep.svg';
import { ReactComponent as BoxIcon } from 'assets/images/tours/box.svg';
import { ReactComponent as BackpackIcon } from 'assets/images/tours/backpack.svg';
import { ReactComponent as GastroIcon } from 'assets/images/tours/gastro.svg';
import { ReactComponent as DaivingIcon } from 'assets/images/tours/daiving.svg';
import { ReactComponent as MotorcycleIcon } from 'assets/images/tours/motorcycle.svg';
import { ReactComponent as FamilyIcon } from 'assets/images/tours/family.svg';
import { ReactComponent as PhotoIcon } from 'assets/images/tours/photo.svg';
import { ReactComponent as BookIcon } from 'assets/images/tours/book.svg';
import { ReactComponent as PointIcon } from 'assets/images/map/object.svg';
import { TNamesTypeTourFilter } from 'shared/types/tours.types';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';
import { ToursTypesSlider } from './ToursTypesSlider';
import { ToursTypesSelect } from './ToursTypesSelect';

type TToursFiltersByType = {
  filters: TFiltersTours;
  clickFilter: (filter: string, fieldFilters: TNamesTypeTourFilter) => void;
  isMulti?: boolean;
};

type TItemFilters = {
  id: string;
  label: string;
  value: string;
  icon: JSX.Element;
  fieldFilters: TNamesTypeTourFilter;
};

const itemsTypeTour: TItemFilters[] = [
  {
    id: 'all',
    label: 'Все предложения',
    value: '',
    icon: <CardsIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'excursion',
    label: TRANSFORM_TEXT.productType.EXCURSION,
    value: TRANSFORM_TEXT.productType.EXCURSION,
    icon: <BusIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'water',
    label: TRANSFORM_TEXT.tourTypeMulti.WATER,
    value: TRANSFORM_TEXT.tourTypeMulti.WATER,
    icon: <FishIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'walking',
    label: TRANSFORM_TEXT.tourTypeMulti.HIKING,
    value: TRANSFORM_TEXT.tourTypeMulti.HIKING,
    icon: <ShoesIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'helicopter',
    label: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
    value: TRANSFORM_TEXT.tourTypeMulti.HELICOPTER,
    icon: <HelicopterIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'snowmobile',
    label: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
    value: TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE,
    icon: <SnowmobileIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'jeep',
    label: TRANSFORM_TEXT.tourTypeMulti.JEEP,
    value: TRANSFORM_TEXT.tourTypeMulti.JEEP,
    icon: <JeepIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'combined',
    label: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
    value: TRANSFORM_TEXT.tourTypeMulti.COMBINED,
    icon: <BoxIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'ethnic',
    label: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
    value: TRANSFORM_TEXT.tourTypeMulti.ETHNIC,
    icon: <EthnicIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'camping',
    label: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
    value: TRANSFORM_TEXT.tourTypeMulti.CAMPING,
    icon: <CampingIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'expeditionary',
    label: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
    value: TRANSFORM_TEXT.tourTypeMulti.EXPEDITIONARY,
    icon: <BackpackIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'gastronomic',
    label: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
    value: TRANSFORM_TEXT.tourTypeMulti.GASTRONOMIC,
    icon: <GastroIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'daiving',
    label: TRANSFORM_TEXT.tourTypeMulti.DIVING,
    value: TRANSFORM_TEXT.tourTypeMulti.DIVING,
    icon: <DaivingIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'motobike',
    label: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
    value: TRANSFORM_TEXT.tourTypeMulti.MOTORBIKE,
    icon: <MotorcycleIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'family',
    label: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
    value: TRANSFORM_TEXT.tourTypeMulti.FAMILY,
    icon: <FamilyIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'photography',
    label: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
    value: TRANSFORM_TEXT.tourTypeMulti.PHOTOGRAPHY,
    icon: <PhotoIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
  {
    id: 'trainig',
    label: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
    value: TRANSFORM_TEXT.tourTypeMulti.TRAINING,
    icon: <BookIcon className='tours-filters__type__icon' />,
    fieldFilters: 'types',
  },
];

const itemsLocationTour: TItemFilters[] = [
  {
    id: 'putorana',
    label: 'Плато Путорана',
    value: TRANSFORM_TEXT.locationsTourForFilter.PUTORANA,
    icon: <PointIcon className='tours-filters__type__icon' />,
    fieldFilters: 'locations',
  },
  {
    id: 'anabar',
    label: 'Плато Анабар',
    value: TRANSFORM_TEXT.locationsTourForFilter.ANABAR,
    icon: <PointIcon className='tours-filters__type__icon' />,
    fieldFilters: 'locations',
  },
];

const itemsFilters = [...itemsTypeTour, ...itemsLocationTour];

export const ToursFiltersByType = ({ filters, clickFilter, isMulti }: TToursFiltersByType) => {
  const [isMobile] = useMediaQuery(['(max-width: 1123px)']);

  return isMobile ? (
    <ToursTypesSelect filters={filters} clickFilter={clickFilter} itemsFilters={itemsFilters} isMulti={isMulti} />
  ) : (
    <ToursTypesSlider filters={filters} clickFilter={clickFilter} itemsFilters={itemsFilters} />
  );
};
