import React, { useState } from 'react';
import ApplicationPreview from '../../../features/application-preview/ui';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import s from './index.module.scss';
import { TRequestFetch } from '../../../../shared/types/request.types';
import { useAppSelector } from 'shared/hooks/useAppSelector';

type Props = {
  requests: IRequestTypes[];
};

const cvaWrapper = cva([s.wrapper]);
const cvaColumn = cva([s.column]);
const cvaHead = cva([s.head], {
  variants: {
    type: {
      NEW: [s.available],
      PROCESSING: [s.now],
      DONE: [s.end],
      DENIED: [s.unaccepted],
      DELETED: [s.unaccepted],
    },
  },
});

interface IRequestTypes {
  name: string;
  status: string;
  requests: TRequestFetch[];
}

export default function ApplicationList({ requests }: Props) {
  return (
    <div className={clsx(cvaWrapper(), 'scrollbar')}>
      {requests.map((type, i) => (
        <Column key={type.name} type={type}/>
      ))}
    </div>
  );
}

type ColumnProps = {
  type: IRequestTypes
}

const Column = ({type}: ColumnProps) => {
  const userData = useAppSelector((state) => state.user.userData);
  const [lengthReq, setLengthReq] = useState(type.requests.length)
  return (
    <div className={cvaColumn()}>
      <div
        className={cvaHead({
          //@ts-ignore: todo добавить enum
          type: type.status,
        })}
      >
        <span>{type.name}</span>
        <span>{lengthReq}</span>
      </div>
      {type.requests.map((application) => (
        <ApplicationPreview onDelete={() => setLengthReq(lengthReq - 1)} userData={userData} application={application} key={application.id} />
      ))}
    </div>
  )
}