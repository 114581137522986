import { Datagrid, List, TextField, EditButton, ShowButton, Pagination } from 'react-admin';
import { MENU_ADMIN_LIST, LOCATIONS3D_FIELDS } from '../../../shared/constants/const';

const Locations3dList = () => (
  <List title={MENU_ADMIN_LIST.locations3d} exporter={false} pagination={<Pagination rowsPerPageOptions={[]} />}>
    <Datagrid bulkActionButtons={false} sx={{ '& .RaDatagrid-rowCell': { padding: '6px 5px' } }}>
      <TextField source='name' sortable={false} label={LOCATIONS3D_FIELDS.title} />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export default Locations3dList;
