import { memo } from 'react';
import { ReactComponent as Map } from 'assets/map/map.svg';
// import mapw from 'assets/map/mapw2.webp';
// import tile_100 from 'assets/map/brandmap/Tile_100.webp';

// import tile_99 from 'assets/map/brandmap/Tile_099.webp';
// import tile_98 from 'assets/map/brandmap/Tile_098.webp';
// import tile_97 from 'assets/map/brandmap/Tile_097.webp';
// import tile_96 from 'assets/map/brandmap/Tile_096.webp';
// import tile_95 from 'assets/map/brandmap/Tile_095.webp';
// import tile_94 from 'assets/map/brandmap/Tile_094.webp';
// import tile_93 from 'assets/map/brandmap/Tile_093.webp';
// import tile_92 from 'assets/map/brandmap/Tile_092.webp';
// import tile_91 from 'assets/map/brandmap/Tile_091.webp';
// import tile_90 from 'assets/map/brandmap/Tile_090.webp';

// import tile_89 from 'assets/map/brandmap/Tile_089.webp';
// import tile_88 from 'assets/map/brandmap/Tile_088.webp';
// import tile_87 from 'assets/map/brandmap/Tile_087.webp';
// import tile_86 from 'assets/map/brandmap/Tile_086.webp';
// import tile_85 from 'assets/map/brandmap/Tile_085.webp';
// import tile_84 from 'assets/map/brandmap/Tile_084.webp';
// import tile_83 from 'assets/map/brandmap/Tile_083.webp';
// import tile_82 from 'assets/map/brandmap/Tile_082.webp';
// import tile_81 from 'assets/map/brandmap/Tile_081.webp';
// import tile_80 from 'assets/map/brandmap/Tile_080.webp';

// import tile_79 from 'assets/map/brandmap/Tile_079.webp';
// import tile_78 from 'assets/map/brandmap/Tile_078.webp';
// import tile_77 from 'assets/map/brandmap/Tile_077.webp';
// import tile_76 from 'assets/map/brandmap/Tile_076.webp';
// import tile_75 from 'assets/map/brandmap/Tile_075.webp';
// import tile_74 from 'assets/map/brandmap/Tile_074.webp';
// import tile_73 from 'assets/map/brandmap/Tile_073.webp';
// import tile_72 from 'assets/map/brandmap/Tile_072.webp';
// import tile_71 from 'assets/map/brandmap/Tile_071.webp';
// import tile_70 from 'assets/map/brandmap/Tile_070.webp';

// import tile_69 from 'assets/map/brandmap/Tile_069.webp';
// import tile_68 from 'assets/map/brandmap/Tile_068.webp';
// import tile_67 from 'assets/map/brandmap/Tile_067.webp';
// import tile_66 from 'assets/map/brandmap/Tile_066.webp';
// import tile_65 from 'assets/map/brandmap/Tile_065.webp';
// import tile_64 from 'assets/map/brandmap/Tile_064.webp';
// import tile_63 from 'assets/map/brandmap/Tile_063.webp';
// import tile_62 from 'assets/map/brandmap/Tile_062.webp';
// import tile_61 from 'assets/map/brandmap/Tile_061.webp';
// import tile_60 from 'assets/map/brandmap/Tile_060.webp';

// import tile_59 from 'assets/map/brandmap/Tile_059.webp';
// import tile_58 from 'assets/map/brandmap/Tile_058.webp';
// import tile_57 from 'assets/map/brandmap/Tile_057.webp';
// import tile_56 from 'assets/map/brandmap/Tile_056.webp';
// import tile_55 from 'assets/map/brandmap/Tile_055.webp';
// import tile_54 from 'assets/map/brandmap/Tile_054.webp';
// import tile_53 from 'assets/map/brandmap/Tile_053.webp';
// import tile_52 from 'assets/map/brandmap/Tile_052.webp';
// import tile_51 from 'assets/map/brandmap/Tile_051.webp';
// import tile_50 from 'assets/map/brandmap/Tile_050.webp';

// import tile_49 from 'assets/map/brandmap/Tile_049.webp';
// import tile_48 from 'assets/map/brandmap/Tile_048.webp';
// import tile_47 from 'assets/map/brandmap/Tile_047.webp';
// import tile_46 from 'assets/map/brandmap/Tile_046.webp';
// import tile_45 from 'assets/map/brandmap/Tile_045.webp';
// import tile_44 from 'assets/map/brandmap/Tile_044.webp';
// import tile_43 from 'assets/map/brandmap/Tile_043.webp';
// import tile_42 from 'assets/map/brandmap/Tile_042.webp';
// import tile_41 from 'assets/map/brandmap/Tile_041.webp';
// import tile_40 from 'assets/map/brandmap/Tile_040.webp';

// import tile_39 from 'assets/map/brandmap/Tile_039.webp';
// import tile_38 from 'assets/map/brandmap/Tile_038.webp';
// import tile_37 from 'assets/map/brandmap/Tile_037.webp';
// import tile_36 from 'assets/map/brandmap/Tile_036.webp';
// import tile_35 from 'assets/map/brandmap/Tile_035.webp';
// import tile_34 from 'assets/map/brandmap/Tile_034.webp';
// import tile_33 from 'assets/map/brandmap/Tile_033.webp';
// import tile_32 from 'assets/map/brandmap/Tile_032.webp';
// import tile_31 from 'assets/map/brandmap/Tile_031.webp';
// import tile_30 from 'assets/map/brandmap/Tile_030.webp';

// import tile_29 from 'assets/map/brandmap/Tile_029.webp';
// import tile_28 from 'assets/map/brandmap/Tile_028.webp';
// import tile_27 from 'assets/map/brandmap/Tile_027.webp';
// import tile_26 from 'assets/map/brandmap/Tile_026.webp';
// import tile_25 from 'assets/map/brandmap/Tile_025.webp';
// import tile_24 from 'assets/map/brandmap/Tile_024.webp';
// import tile_23 from 'assets/map/brandmap/Tile_023.webp';
// import tile_22 from 'assets/map/brandmap/Tile_022.webp';
// import tile_21 from 'assets/map/brandmap/Tile_021.webp';
// import tile_20 from 'assets/map/brandmap/Tile_020.webp';

// import tile_19 from 'assets/map/brandmap/Tile_019.webp';
// import tile_18 from 'assets/map/brandmap/Tile_018.webp';
// import tile_17 from 'assets/map/brandmap/Tile_017.webp';
// import tile_16 from 'assets/map/brandmap/Tile_016.webp';
// import tile_15 from 'assets/map/brandmap/Tile_015.webp';
// import tile_14 from 'assets/map/brandmap/Tile_014.webp';
// import tile_13 from 'assets/map/brandmap/Tile_013.webp';
// import tile_12 from 'assets/map/brandmap/Tile_012.webp';
// import tile_11 from 'assets/map/brandmap/Tile_011.webp';
// import tile_10 from 'assets/map/brandmap/Tile_010.webp';

// import tile_9 from 'assets/map/brandmap/Tile_009.webp';
// import tile_8 from 'assets/map/brandmap/Tile_008.webp';
// import tile_7 from 'assets/map/brandmap/Tile_007.webp';
// import tile_6 from 'assets/map/brandmap/Tile_006.webp';
// import tile_5 from 'assets/map/brandmap/Tile_005.webp';
// import tile_4 from 'assets/map/brandmap/Tile_004.webp';
// import tile_3 from 'assets/map/brandmap/Tile_003.webp';
// import tile_2 from 'assets/map/brandmap/Tile_002.webp';
// import tile_1 from 'assets/map/brandmap/Tile_001.webp';

export const Svg = () => <Map />;

// const tiles = [
//   tile_100,
//   tile_99,
//   tile_98,
//   tile_97,
//   tile_96,
//   tile_95,
//   tile_94,
//   tile_93,
//   tile_92,
//   tile_91,
//   tile_90,

//   tile_89,
//   tile_88,
//   tile_87,
//   tile_86,
//   tile_85,
//   tile_84,
//   tile_83,
//   tile_82,
//   tile_81,
//   tile_80,

//   tile_79,
//   tile_78,
//   tile_77,
//   tile_76,
//   tile_75,
//   tile_74,
//   tile_73,
//   tile_72,
//   tile_71,
//   tile_70,

//   tile_69,
//   tile_68,
//   tile_67,
//   tile_66,
//   tile_65,
//   tile_64,
//   tile_63,
//   tile_62,
//   tile_61,
//   tile_60,

//   tile_59,
//   tile_58,
//   tile_57,
//   tile_56,
//   tile_55,
//   tile_54,
//   tile_53,
//   tile_52,
//   tile_51,
//   tile_50,

//   tile_49,
//   tile_48,
//   tile_47,
//   tile_46,
//   tile_45,
//   tile_44,
//   tile_43,
//   tile_42,
//   tile_41,
//   tile_40,

//   tile_39,
//   tile_38,
//   tile_37,
//   tile_36,
//   tile_35,
//   tile_34,
//   tile_33,
//   tile_32,
//   tile_31,
//   tile_30,

//   tile_29,
//   tile_28,
//   tile_27,
//   tile_26,
//   tile_25,
//   tile_24,
//   tile_23,
//   tile_22,
//   tile_21,
//   tile_20,

//   tile_19,
//   tile_18,
//   tile_17,
//   tile_16,
//   tile_15,
//   tile_14,
//   tile_13,
//   tile_12,
//   tile_11,
//   tile_10,

//   tile_9,
//   tile_8,
//   tile_7,
//   tile_6,
//   tile_5,
//   tile_4,
//   tile_3,
//   tile_2,
//   tile_1,
// ];

// export const Svg = () => {
//   return (
//     <picture
//       style={{
//         width: '130px',
//         height: '130px',
//         display: 'grid',
//         gridTemplateColumns: 'repeat(10, 13px)',
//       }}
//     >
//       {/* <img style={{ width: '130px', height: '130px', display: 'block' }} src={mapw} alt='' /> */}
//       {tiles.map((tile, index) => (
//         <img style={{ width: '13px', height: '13px', display: 'block' }} src={tile} alt='' key={tile} />
//       ))}
//     </picture>
//   );
// };

export default memo(Svg);
