import api from 'http/index';
import commonApi from 'http/commonApi';
import { store } from '../store';
import { logout, refreshTokens } from 'manageStore/user/userSlice';
import {
  TChangeStatusRequest,
  TCreateAuthRequest,
  TCreateNewUserRequest,
  TFetchRequest,
  TFetchStats,
  TGetUnread,
  TSetRead,
  TStatsData,
} from 'shared/types/types';
import {TCreateRequestResponse, TRequestFetch} from 'shared/types/request.types';
import {TDocFetchWithUser, TSharedDocs } from 'shared/types/common.types';

export default class RequestService {
  static async createRequest(data: TCreateNewUserRequest) {
    return commonApi().post<TCreateRequestResponse>('/request/create', { ...data });
  }
  static async createAuthRequest(data: TCreateAuthRequest) {
    return api().post('/request/create-auth', { ...data });
  }
  static async fetchRequests(data: TFetchRequest) {
    return api(store.dispatch, logout, refreshTokens).post<TRequestFetch[]>('/request/', { ...data });
  }
  static async sendFilesWithMessage(data: File, id: number, messageId: number,) {
    const formData = new FormData();
    formData.append('data', data);
    return api(store.dispatch, logout, refreshTokens).post<any>(`/requests/${id}/messages/${messageId}`, formData);
  }
  static async deleteFile(id: number, fileId: number,) {
    return api(store.dispatch, logout, refreshTokens).delete<any>(`/requests/${id}/documents/${fileId}`);
  }
  static async deleteFileInMessage(id: number, messageId: number, fileId: number,) {
    return api(store.dispatch, logout, refreshTokens).delete<any>(`/requests/${id}/message/${messageId}/documents/${fileId}`);
  }
  static async fetchById(id: number) {
    return api(store.dispatch, logout, refreshTokens).get<TRequestFetch>(`/request/${id}`);
  }
  static async deleteRequest(id: number) {
    return api(store.dispatch, logout, refreshTokens).delete(`/request/${id}`);
  }
  static async fetchStats(data: TFetchStats) {
    return api(store.dispatch, logout, refreshTokens).post<TStatsData[]>(`/request/stats`, data);
  }
  static async getUnread(data: TGetUnread) {
    return api(store.dispatch, logout, refreshTokens).post(`/request/unread`, data);
  }
  static async getDeleted() {
    return api(store.dispatch, logout, refreshTokens).post(`/request/deleted`);
  }
  static async setRead(data: TSetRead) {
    return api(store.dispatch, logout, refreshTokens).post(`/request/setread`, data);
  }
  static async changeStatus(data: TChangeStatusRequest) {
    return api(store.dispatch, logout, refreshTokens).post(`/request/change-status`, data);
  }
  static async paidOrder(id: number) {
    return api(store.dispatch, logout, refreshTokens).post(`/request/${id}/paid`);
  }
  static async sendDoc(id: number, data: FormData, fileName: string) {
    return api(store.dispatch, logout, refreshTokens).post<TSharedDocs[]>(
      `/requests/${id}/documents?caption=${fileName}`,
      data
    );
  }
  static async getDocsList(id: number) {
    return api(store.dispatch, logout, refreshTokens).get<TDocFetchWithUser[]>(`/requests/${id}/documents`);
  }
  static async getDocById(id: number) {
    return api(store.dispatch, logout, refreshTokens).get(`/documents/${id}`);
  }

  static async getDownloadExcel() {
    return api(store.dispatch, logout, refreshTokens).get(`/request/download-excel`, {
      responseType: 'blob'
    });
  }
}
