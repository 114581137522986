import { Create } from 'react-admin';
import Locations3dForm from './Locations3dForm';

const Locations3dCreate = () => (
  <Create>
    <Locations3dForm />
  </Create>
);

export default Locations3dCreate;
