import { ScreenSpaceEventType, KeyboardEventModifier } from 'cesium';

export const URL_FOR_IMG = window?.visit_arctic_russia?.URL_FOR_IMG;
export const API_BASE_URL = window?.visit_arctic_russia?.API_BASE_URL;
export const SOCKET_URL = window?.visit_arctic_russia?.API_CHAT + '/api/ws';

export const APP_BASE_URL = window?.visit_arctic_russia?.APP_BASE_URL || 'https://norilsk.webinnovations.ru';

export const TOKENS_CESIUM = {
  dudinka: {
    //norilsk2035
    value:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmMTRiY2U3Yy04NWRmLTRkZWQtYmJjNi04N2VkMWQ0MGE3Y2EiLCJpZCI6MTMyOTU5LCJpYXQiOjE2ODQ2ODkzMTR9.ISi0POtxLMAS1u0UiH_VYn4JdfzUhdAUgsHMVDR52wI',
    id: 'f14bce7c-85df-4ded-bbc6-87ed1d40a7ca',
  },
  // dudinka: {
  //   value:
  //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NDZiNmQ5Yy02ODdiLTRkNWYtOTMwMy1hNmFlODBiNzgwNDkiLCJpZCI6MTE4NjM0LCJpYXQiOjE2NzE0Mjk5MDB9.E8MfkMMJBCeVJ-eVLRn20jnl85_0_kpllmW_LIrQBoQ',
  //   id: '546b6d9c-687b-4d5f-9303-a6ae80b78049',
  // },
  dudinka_history: {
    value:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MmY4ZTg5Yy1lMDc0LTQxOTAtODYyMi03NjU2OTk1ODU3ODMiLCJpZCI6MTE4NjM0LCJpYXQiOjE2NzIwNzcyMTF9.O2Tta5lkC-guFUcpeH9q0D7Yv9jrKyTXpwhuB8xd-o8',
    id: '52f8e89c-e074-4190-8622-765699585783',
  },
};

export const ScreenSpaceEventTypes = { ...ScreenSpaceEventType };
export const KeyboardEventModifiers = { ...KeyboardEventModifier };

export const TRANSFORM_TEXT = {
  guide: {
    no: 'нет',
    excursovod: 'экскурсовод',
    guidTran: 'гид-переводчик',
    instructor: 'инструктор-проводник',
  },
  food: {
    yes: 'да',
    no: 'нет',
  },
  tourTypeMulti: {
    WATER: 'Водный',
    HIKING: 'Пеший',
    HELICOPTER: 'Вертолетный',
    SNOWMOBILE: 'Снегоходный',
    JEEP: 'Джип-тур',
    COMBINED: 'Комбинированный',
    ETHNIC: 'Этнический',
    CAMPING: 'Поход с палатками',
    EXPEDITIONARY: 'Экспедиционный',
    GASTRONOMIC: 'Гастрономический',
    DIVING: 'Дайвинг-тур',
    MOTORBIKE: 'Мото-тур',
    FAMILY: 'Семейный тур',
    PHOTOGRAPHY: 'Фототур',
    TRAINING: 'Обучающий тур',
  },
  locationsTourForFilter: {
    PUTORANA: 'Плато Путорана',
    ANABAR: 'Плато Анабар',
  },
  season: {
    SUMMER: 'Лето',
    AUTUMN: 'Осень',
    WINTER: 'Зима',
    SPRING: 'Весна',
  },
  role: {
    superAdmin: 'ROLE_SUPERADMIN',
    admin: 'ROLE_ADMIN',
    operator: 'ROLE_OPERATOR',
    tourist: 'ROLE_TOURIST',
    restricted: 'restricted',
  },
  operator: {
    TOUR_OPERATOR: 'Туроператор',
    GUIDE: 'Гид',
  },
  productType: {
    TOUR: 'Тур',
    EXCURSION: 'Экскурсия',
  },
  partners: {
    operator: {
      label: 'Туроператоры',
      value: 'operator',
    },
    excursionBureau: {
      label: 'Экскурсионные бюро',
      value: 'excursionBureau',
    },
    guide: {
      label: 'Экскурсоводы',
      value: 'guide',
    },
    agent: {
      label: 'Проводники',
      value: 'agent',
    },
    souvenirMaster: {
      label: 'Мастера по сувенирам',
      value: 'souvenirMaster',
    },
    otherServices: {
      label: 'Другие услуги',
      value: 'otherServices',
    },
  },
  news: {
    FOOD: 'Еда',
    INDUSTRY: 'Промышленность',
    STORY: 'История',
    EXTREME: 'Экстрим',
    ETHNIC: 'Этника',
    NATURE: 'Природа',
    JOURNEY: 'Путешествие',

    tags: {
      ALL: 'Все',
      PLATO_PUTORANA: 'Плато Путорана',
      NORILSK: 'Норильск',
      DUBINKA: 'Дудинка',
      DIKSON: 'Диксон',
      KHATANGA: 'Хатанга',
      REMOTE_VILAGES: 'Отдаленные поселки',
      ANAVAR_PLATEAUS: 'Плато Анабар',
      ARCTIC_COAST: 'Арктическое побережье',
    }
  },
};

export const MENU_ADMIN_LIST = {
  administration: 'Пользователи',
  operatorRequest: 'Заявки операторов',
  tours: 'Готовые туры',
  photoTours: 'Фото для тура',
  locations: 'Локации',
  guide: 'Путеводитель',
  news: 'Новости',
  beauty: 'Красоты севера',
  gallery: 'Страница галереи',
  lodging: 'Где остановиться',
  food: 'Где поесть',
  attraction: 'Что посмотреть',
  city: 'Города',
  controlTours: 'Управление турами',
  feedback: 'Обратная связь',
  npa: 'НПА туриндустрии',
  locations3d: '3d локации',
  partners: 'Партнеры',
  blog: 'Блог',
  participants: 'Список участников ТРК',
  members: 'Заявки',
  cluster: 'Кластеры',
  analytics: 'аналитика',
  analyticsButton: 'Выгрузить аналитику'
};

export const USERS_FIELDS = {
  email: 'Email',
  role: 'Роль',
  login: 'Логин',
  password: 'Пароль',
  password2: 'Повторите пароль',
};

export const OPERATOR_FIELDS = {
  type: 'Категория',
  registryNumber: 'Реестровая запись',
  org: 'Организация',
  fio: 'ФИО',
  phone: 'Телефон',
  email: 'E-mail',
  fullName: 'Полное название организации',
  shortName: 'Короткое название организации',
  address: 'Адрес',
  ogrn: 'ОГРН',
  inn: 'ИНН',
  contactEmail: 'E-mail',
  contactLastName: 'Фамилия',
  contactFirstName: 'Имя',
  contactPatronymic: 'Отчество',
  contactPhone: 'Телефон',
  lastName: 'Фамилия',
  firstName: 'Имя',
  patronymic: 'Отчество',
  passportSeries: 'Серия',
  passportNumber: 'Номер',
  passportIssueDate: 'Дата выдачи',
  passportIssuedBy: 'Кем выдан',
  certificateSeries: 'Серия',
  certificateNumber: 'Номер',
  certificateIssuedBy: 'Кем выдан',
  certificateIssueDate: 'Дата выдачи',
  certificateExpiry: 'Срок действия',
  languages: 'Иностранный язык',
};

export const TOURS_FIELDS = {
  id: 'id',
  title: 'Название продукта',
  shortDesc: 'Описание продукта',
  preview: 'Основная фотография',
  tourType: 'Тип путешествия',
  season: 'Сезон путешествия',
  level: 'Уровень',
  price: 'Стоимость',
  operator: 'Оператор (почта)',
  desc: 'Описание тура',
  commentLevel: 'Примечание',
  promo: 'Вас ждет',
  benefits: 'Преимущества',
  habitation: 'Проживание',
  habitationPhoto: 'Фотографии проживания',
  included: 'Включено',
  notIncluded: 'Не включено',
  locations: 'Посещаемые локации',
  days: 'Программа путешествия',
  duration: 'Продолжительность',
  productType: 'Тур / Экскурсия',
  minGroupSize: 'Минимальное количество участников',
  maxGroupSize: 'Максимальное количество участников',
  minAge: 'Минимальный  возраст',
  maxAge: 'Максимальный возраст',
  winter: 'Зима',
  spring: 'Весна',
  summer: 'Лето',
  autumn: 'Осень',
  food: 'Питание',
  transfer: 'Услуга трансфера',
  activities: 'Активности, включенные в продукт',
  guide: 'Наличии экскурсовода (гида), гида-переводчика, инструктора-проводника',
  photos: 'Фотографии к описанию продукта',
  additionalServices: 'Дополнительные услуги',
  status: 'Статус',
  statusPublic: 'Статус публикации',
};

export const PARTNERS_FIELDS = {
  id: 'id',
  title: 'Имя или название организации',
  category: 'Категория',
  photos: 'Фотографии',
  description: 'Описание',
  phone: 'Телефон',
  email: 'E-mail',
  site: 'Сайт',
  experience: 'Опыт работы',
  video: 'Код видео',
  products: 'Туры и экскурсии',
};

export const PHOTO_TOURS_FIELDS = {
  id: 'id',
  title: 'Описание (тур и день)',
  photos: 'Фотографии',
};

export const PARTICIPANT_FIELDS = {
  name: 'Название',
  id: 'id',
}
export const NEWS_FIELDS = {
  id: 'id',
  title: 'Заголовок',
  important: 'Это важно',
  isPublished: 'Опубликовано',
  author: 'Автор',
  date: 'Дата создания',
  preview: 'Превью',
  shortDesc: 'Краткое описание',
  content: 'Описание',
  views: 'Views',
  metaDesc: 'Мета тег "Description"',
  metaKeywords: 'Мета тег "Keywords"',
};

export const BEAUTY_FIELDS = {
  id: 'id',
  title: 'Заголовок',
  name: 'Название красоты севера',
  subtitle: 'Подзаголовок',
  location: 'Локация',
  preview: 'Превью',
  shortDesc: 'Краткое описание',
  content: 'Полное описание',
  video: 'Код видео',
  gallery: 'Галерея',
};

export const GALLERY_FIELDS = {
  id: 'id',
  uploadedFiles: 'Изображения',
};

export const LODGING_FIELDS = {
  id: 'id',
  title: 'Название локации',
  city: 'Город',
  time: 'Время работы',
  phone: 'Телефон',
  address: 'Адрес',
  preview: 'Превью',
  coords: 'Координаты',
  description: 'Описание',
  email: 'Почта',
  site: 'Сайт',
};

export const FOOD_FIELDS = {
  id: 'id',
  title: 'Название локации',
  city: 'Город',
  time: 'Время работы',
  contacts: 'Контакты',
  preview: 'Превью',
  coords: 'Координаты',
  description: 'Описание',
};

export const ATTRACTION_FIELDS = {
  id: 'id',
  title: 'Название локации',
  city: 'Город',
  time: 'Время работы',
  contacts: 'Контакты',
  preview: 'Превью',
  coords: 'Координаты',
  description: 'Описание',
};

export const CITY_FIELDS = {
  id: 'id',
  title: 'Название города',
  preview: 'Превью',
  coords: 'Координаты',
  description: 'Описание',
  gallery: 'Галерея',
  territory: 'Кластер',
};

export const CLASTER_FIELD = {
  id: 'id',
  name: 'Название'
}
export const NPA_FIELDS = {
  id: 'id',
  title: 'Название',
  file: 'Документ',
};

export const LOCATIONS3D_FIELDS = {
  id: 'id',
  title: 'Название',
  idLocation: 'id локации',
  description: 'Описание',
  coords: 'Координаты',
};

export const STATUS_TYPE = Object.freeze({
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DRAFT: 'DRAFT',
});

export const PLACE_TYPES = {
  food: 'food',
  attraction: 'attraction',
  lodging: 'lodging',
};

export const USER_TYPES = {
  tourist: 'TOURIST',
  operator: 'OPERATOR',
};

export const REQUEST_STATUSSES = {
  new: 'NEW',
  processing: 'PROCESSING',
  done: 'DONE',
  denied: 'DENIED',
  deleted: 'DELETED',
};

export const BLOG_FIELDS = {
  id: 'id',
  preview : 'Основная фотография',
  name: 'Название локации',
  description : 'Описание локации',
  territoryTag : 'Тег локации',
  typeTags:  'Тег тематики',
  relatedBlogs : 'Другие статьи',
  galleryWithAuthor: 'Галерея',
  facts : 'Важные факты',
  photo	: 'Доп. фотография',
  interest:    'Что посмотреть',
  guidebooks : 'Путеводитель',
  locations  : 'Место на карте',
  tours: 'Туры и экскурсии',
  video: 'Видео',

  isImportant: 'Важно',
  isPublished:	'Показать',
  createdDate:	'Дата',
  metaDesc:	'мета описание',
  metaKeywords:'мета ключи',

  nextBlog: 'Следующая статья',
  author: 'Автор фото'
};



