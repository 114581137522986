import classNames from 'classnames';
import { PropsWithChildren, MouseEvent } from 'react';
import ReactDom from 'react-dom';

type TPortalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  isBottom?: boolean;
  classContainer?: string;
  classOverlay?: string;
};

export const Portal = ({
  children,
  isOpen,
  onClose,
  isBottom,
  classContainer,
  classOverlay,
}: PropsWithChildren<TPortalProps>) => {
  const portalElement = document.querySelector('#portal');
  if (!portalElement) {
    return null;
  }

  const handleClick = ({ target, currentTarget }: MouseEvent<HTMLDivElement>) => {
    if (onClose && target === currentTarget) {
      onClose();
    }
  };

  return ReactDom.createPortal(
    isOpen ? (
      <>
        <div className={classNames('portal__overlay', classOverlay)} onClick={onClose}></div>
        <section
          className={classNames('portal__container', classContainer, { 'align-bottom': isBottom })}
          onClick={handleClick}
        >
          {children}
        </section>
      </>
    ) : null,
    portalElement
  );
};
