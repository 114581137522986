import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider, { Settings } from 'react-slick';
import { TRANSFORM_TEXT } from 'shared/constants/const';
import { ReactComponent as ArrowButton } from 'assets/images/arrow-button.svg';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { ButtonSlider } from 'shared/lib/ButtonSlider/ButtonSlider';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { useSwipe } from 'shared/hooks/useSwipe';
import { useClientRect } from 'shared/hooks/useClientRect';

const itemsImpression = [
  {
    id: 1,
    title: 'Вертолётные туры',
    description:
      'Самые яркие и незабываемые впечатления остаются после вертолётных туров. Это возможность побывать в самых труднодоступных и самых красивых местах Арктики. Это невероятные виды плато Путорана — каньоны, водопады, реки и озёра!',
    filter: [TRANSFORM_TEXT.tourTypeMulti.HELICOPTER],
  },
  {
    id: 2,
    title: 'Снегоходы и квадроциклы',
    description:
      'Адреналин, скорость, возможность добраться до труднодоступных мест, любоваться красотой Таймыра. Всё это про туры на снегоходах и квадроциклах. Такие поездки остаются в памяти, как необыкновенные приключения!',
    filter: [TRANSFORM_TEXT.tourTypeMulti.SNOWMOBILE],
  },
  {
    id: 0,
    title: 'Круизные туры',
    description:
      'Круизы по Енисею и Хатанге, по Карскому морю и морю Лаптевых запомнятся надолго. Это уникальная возможность побывать в самобытных поселках, где сохраняют культуру Севера, увидеть потрясающие пейзажи, которые меняются по пути следования круиза!',
    filter: [TRANSFORM_TEXT.tourTypeMulti.WATER],
  },
];

const settingsSlider: Settings = {
  dots: true,
  infinite: false,
  speed: 500,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <ButtonSlider right light />,
  prevArrow: <ButtonSlider left light />,
};

export const HomeImpression = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [paddingSlider, setPaddingSlider] = useState(60);
  const [currentSlider, setCurrentSlider] = useState(0);

  const slickTrackRef = useRef<{ node: HTMLDivElement | null }>({ node: null });

  const [startSwipe, endSwipe, isSwipe] = useSwipe();
  const [sectionRect, sectionRef] = useClientRect();

  const handleClickCard = (types: string[]) => {
    dispatch(setFilters({ ...defaultFiltersTours, types }));
    history.push('/tours');
  };

  const handleChangeSlide = (prev: number, next: number) => {
    setCurrentSlider(next);
  };

  const handleInitSlider = () => {
    slickTrackRef.current.node = document.querySelector('.home-impression__slider .slick-track');
    handleChangeSlide(0, 0);
  };

  useEffect(() => {
    if (!sectionRect?.width) return;
    const paddingContainer = window.innerWidth < 1124 ? 15 : 40;
    const centerPadding = Math.max((sectionRect.width - 1200) / 4, paddingContainer / 2);
    setPaddingSlider(centerPadding);
  }, [sectionRect]);

  useEffect(() => {
    const slickTrackNode = slickTrackRef.current.node;
    if (!slickTrackNode) return;
    let deltaTransform = 0;
    if (currentSlider === 0) {
      deltaTransform = -paddingSlider;
    } else if (currentSlider === itemsImpression.length - 1) {
      deltaTransform = paddingSlider;
    }
    setTimeout(() => {
      slickTrackNode.style.left = `${deltaTransform}px`;
      slickTrackNode.style.transition = `${slickTrackNode.style.transition}, left 400ms ease 0s`;
    }, 0);
  }, [currentSlider, paddingSlider]);

  return (
    <section className='home-impression'>
      <div className='container home-impression__container'>
        <div className='home-impression__inner' ref={sectionRef}>
          <TitleSectionMain title='Исследуй Таймыр с нами' />
          <Slider
            {...settingsSlider}
            beforeChange={handleChangeSlide}
            className='home-impression__slider'
            swipeEvent={startSwipe}
            centerPadding={`${paddingSlider}px`}
            onInit={handleInitSlider}
          >
            {itemsImpression.map((item) => (
              <div key={item.id} className='home-impression__item'>
                <TitleSectionMain className='home-impression__item__title' title={item.title} />
                <p className='home-impression__item__text text'>{item.description}</p>
                <button
                  className='home-impression__item__btn'
                  onMouseDown={endSwipe}
                  onClick={() => !isSwipe && handleClickCard(item.filter)}
                >
                  Узнай больше
                  <ArrowButton />
                </button>
                <div className='home-impression__img'></div>
                <div className='home-impression__background'></div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
