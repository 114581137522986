import { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { setUserData, selectUserData } from 'manageStore/user/userSlice';
import UserService from 'services/user.service';
import Notification from 'shared/lib/notification';
import PasswordStrengthBar from 'react-password-strength-bar';

type TChangePassCardProps = {
  cancelEditing?: () => void;
  setOpenPortal?: (isOpen: boolean) => void;
};

const ChangePassCard = ({ cancelEditing, setOpenPortal }: TChangePassCardProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserData);
  const handleEditing = () => {
    if (cancelEditing) {
      cancelEditing();
    }
    formik.resetForm();
  };

  const [saving, setSaving] = useState(false);

  const formik = useFormik({
    initialValues: {
      oldPass: '',
      newPass: '',
      newPass2: '',
    },
    validationSchema: Yup.object({
      oldPass: Yup.string().required('Заполните поле'),
      newPass: Yup.string()
        .min(5, 'Длина пароля должна быть не менее 5 символов')
        .max(20, 'Длина пароля должна быть не более 20 символов')
        .required('Заполните поле'),
      newPass2: Yup.string()
        .min(5, 'Длина пароля должна быть не менее 5 символов')
        .max(20, 'Длина пароля должна быть не более 20 символов')
        .oneOf([Yup.ref('newPass')], 'Пароли должны совпадать')
        .required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      if (!user) return;
      setSaving(true);
      try {
        const { oldPass, newPass, newPass2 } = values;
        const { data } = await Notification.promise(UserService.updatePassword(user.id, oldPass, newPass, newPass2));
        localStorage.setItem('token', data.accessToken);
        dispatch(setUserData(data.user));
        if (setOpenPortal) {
          setOpenPortal(false);
        }
        handleEditing();
      } catch (e) {
      } finally {
        setSaving(false);
      }
    },
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='profile-contacts'>
        <div className='profile-box'>
          <h4 className='profile-title'>Текущий пароль:</h4>
          <input className='profile-input' id='oldPass' type='password' {...formik.getFieldProps('oldPass')} />
          {formik.touched.oldPass && formik.errors.oldPass ? (
            <div className='profile-error'>{formik.errors.oldPass}</div>
          ) : null}
        </div>
      </div>
      <div className='profile-contacts'>
        <div className='profile-box'>
          <h4 className='profile-title'>Новый пароль:</h4>
          <input className='profile-input' id='newPass' type='password' {...formik.getFieldProps('newPass')} />
          {formik.touched.newPass && formik.errors.newPass ? (
            <div className='profile-error'>{formik.errors.newPass}</div>
          ) : null}
          <PasswordStrengthBar
            password={formik.getFieldProps('newPass').value}
            minLength={5}
            shortScoreWord={'слишком короткий'}
            scoreWords={['очень простой', 'простой', 'нормальный', 'хороший', 'сложный']}
          />
        </div>
        <div className='profile-box'>
          <h4 className='profile-title'>Повторите новый пароль:</h4>
          <input className='profile-input' id='newPass2' type='password' {...formik.getFieldProps('newPass2')} />
          {formik.touched.newPass2 && formik.errors.newPass2 ? (
            <div className='profile-error'>{formik.errors.newPass2}</div>
          ) : null}
        </div>
      </div>
      <div className='profile-bottom'>
        <button className='profile-btn btn red' type='submit' disabled={saving}>
          Сохранить
        </button>
        {cancelEditing && (
          <button className='profile-btn btn blue' onClick={cancelEditing} disabled={saving}>
            Отмена
          </button>
        )}
      </div>
    </form>
  );
};

export default ChangePassCard;
