import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TLocation } from 'shared/types/location.types';
import { setNeedShowLocation } from 'manageStore/designer/designer.slice';
import { TravelGuideItemOsmMap } from './TravelGuideItemOsmMap';

type TTravelGuideItemMapProps = {
  location: TLocation;
  backColor: 'black' | 'beige' | 'blue';
  afterColor: 'white' | 'black' | 'blue';
  isCity: boolean;
};

export const TravelGuideItemMap = ({ location, backColor, afterColor, isCity }: TTravelGuideItemMapProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const toDesigner = () => {
    dispatch(setNeedShowLocation(location));
    history.push('/designer');
  };

  const coords = useMemo(() => {
    if (!location) {
      return [88.224851, 69.346734];
    }

    return !!location.lng && !!location.lat ? [location.lng, location.lat] : [88.224851, 69.346734];
  }, [location]);

  return (
    <section className={`travel-guide-item-map ${backColor}`}>
      <div className='container travel-guide-item-map__container'>
        <div className='travel-guide-item-map__title'>место на карте</div>
        <div className={`travel-guide-item-map__map ${afterColor}`}>
          <TravelGuideItemOsmMap location={coords} />
          <button className='travel-guide-item-map__designer-btn' type='button' onClick={toDesigner}>
            <span>посмотреть на карте</span>
          </button>
        </div>
      </div>
    </section>
  );
};
