import { URL_FOR_IMG } from 'shared/constants/const';
import { declensionNoun } from 'helpers/declensionNoun';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { TReviewFetchResponse } from 'shared/types/review.types';
import { ReviewRating } from './ReviewRating';

type TReviewHeaderProps = {
  reviewItem: TReviewFetchResponse;
  reviewEdit: boolean;
  handleEdit: () => void;
};

export const ReviewHeader = ({ reviewItem, reviewEdit, handleEdit }: TReviewHeaderProps) => {
  const userData = useAppSelector((state) => state.user.userData);
  const { user } = reviewItem;

  return (
    <div className='review-header'>
      <div className='review-header__user-photo'>
        {user.photo?.path ? (
          <img src={`${URL_FOR_IMG}/${user.photo.path}`} alt='Фото' />
        ) : (
          <div>{user.login[0] || ''}</div>
        )}
      </div>
      <p className='review-header__text login bold'>{user.login}</p>
      <p className='review-header__text email'>{user.email}</p>
      <p className='review-header__text date-start bold'>
        Дата начала:{' '}
        <span>{reviewItem.createdDate ? new Date(reviewItem.createdDate).toLocaleDateString('ru-Ru') : '-'}</span>
      </p>
      <p className='review-header__text duration bold'>
        Длительность:{' '}
        <span>
          {reviewItem.tour.duration
            ? `${reviewItem.tour.duration} ${declensionNoun(reviewItem.tour.duration, 'day')}`
            : '-'}
        </span>
      </p>
      {!reviewEdit && <ReviewRating rating={reviewItem.rating} />}
      <p className='review-header__text name bold'>{reviewItem.tour.title}</p>
      <p className='review-header__text price bold'>
        Cтоимость: <span>{reviewItem.tour.price || '-'}</span>
      </p>
      {userData?.type === 'TOURIST' && !reviewEdit && (
        <p className='review-header__text edit bold' onClick={handleEdit}>
          <span>Редактировать</span>
        </p>
      )}
      <p className='review-header__text last-message'>
        {reviewItem.updatedDate
          ? new Date(reviewItem.updatedDate).toLocaleString('ru-Ru')
          : new Date(reviewItem.createdDate).toLocaleString('ru-Ru')}
      </p>
    </div>

    // <div className='Amain-review__head'>
    //   <div className='Amain-review__column'>
    //     <div className='Amain-review__user'>
    //       <div className='Amain-review__user-img'>
    //         {user.photo?.path ? (
    //           <img src={`${URL_FOR_IMG}/${user.photo.path}`} alt='Фото' />
    //         ) : (
    //           <div>{user.login[0] || ''}</div>
    //         )}
    //       </div>
    //       <p className='Amain-review__user-name'>{user.login}</p>
    //       <p className='Amain-review__user-email'>{user.email}</p>
    //     </div>
    //     <p className='Amain-review__tour'>{reviewItem.tour.title}</p>
    //   </div>
    //   <div className='Amain-review__column'>
    //     <p className='Amain-review__datestart'>
    //       Дата начала:{' '}
    //       <span>{reviewItem.createdDate ? new Date(reviewItem.createdDate).toLocaleDateString('ru-Ru') : '-'}</span>
    //     </p>
    //     <p className='Amain-review__duration'>
    //       Длительность:{' '}
    //       <span>
    //         {reviewItem.tour.duration
    //           ? `${reviewItem.tour.duration} ${declensionNoun(reviewItem.tour.duration, 'day')}`
    //           : '-'}
    //       </span>
    //     </p>
    //     <p className='Amain-review__price'>
    //       Cтоимость: <span>{reviewItem.tour.price || '-'}</span>
    //     </p>
    //     {userData?.type === 'TOURIST' && !reviewEdit && (
    //       <button className='Amain-review__btn' onClick={handleEdit}>
    //         Редактировать
    //       </button>
    //     )}
    //   </div>

    //   <div className='Amain-review__column'>
    //     <ReviewRating rating={reviewItem.rating} />
    //     <p className='Amain-review__time'>
    //       {reviewItem.updatedDate
    //         ? new Date(reviewItem.updatedDate).toLocaleString('ru-Ru')
    //         : new Date(reviewItem.createdDate).toLocaleString('ru-Ru')}
    //     </p>
    //   </div>
    // </div>
  );
};
