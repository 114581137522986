import { useEffect, useMemo } from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { useFormContext, useFormState, useController } from 'react-hook-form';
import CesiumMap from 'components/CesiumMap/CesiumMap';
import { LOCATIONS_FIELDS } from 'shared/constants/locations.constants';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { selectHistoryCesium } from 'manageStore/cesium/cesium.select';

export const CesiumMapField = () => {
  const record = useRecordContext();
  const history = selectHistoryCesium();
  const { errors } = useFormState();
  const { setValue, clearErrors } = useFormContext();
  useController({ name: 'cesiumId', rules: { required: true } });
  useController({ name: 'history' });

  const setIdFieldForm = (cesiumId: number) => {
    setValue('cesiumId', cesiumId, { shouldDirty: true });
    if (errors.cesiumId) {
      clearErrors('cesiumId');
    }
  };

  const CesiumMapMemo = useMemo(
    () => (
      <CesiumMap
        setIdFieldForm={setIdFieldForm}
        typeClick='setMarker'
        idTileset={record?.cesiumId}
        defaultHistory={record?.history}
      />
    ),
    []
  );

  useEffect(() => {
    const cesiumId = record?.history === history ? record?.cesiumId : undefined;
    setValue('cesiumId', cesiumId);
    setValue('history', history);
  }, [history]);

  return (
    <>
      <Labeled label={LOCATIONS_FIELDS.coords} fullWidth>
        <></>
      </Labeled>
      <div className='tours__content cesium-map' style={{ width: '100%', height: '500px' }}>
        {CesiumMapMemo}
      </div>
      <ErrorMessage message={errors?.cesiumId ? 'Required' : null} />
    </>
  );
};
