import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { BaseAccordion } from 'shared/ui/BaseAccordion/BaseAccordion';

export const HomeFaq = () => {
  return (
    <section className='home-faq'>
      <div className='container home-faq__container'>
        <TitleSectionMain className='home-faq__title' title='вопросы и ответы' isSmall />
        <div className='faq-list'>
          <BaseAccordion question='Как добраться до Таймыра'>
            <article className='home-faq__answer'>
              <p>
                Самолетом можно долететь до международного аэропорта «Норильск» имени Николая Урванцева из Москвы,
                Санкт-Петербурга, Красноярска, Абакана, Новосибирска, Уфы и Баку...
              </p>
              <p>
                Летом, в период навигации, из Красноярска можно прийти на теплоходе по реке Енисей до города Дудинка
                (примерно четверо суток в пути)
              </p>
            </article>
          </BaseAccordion>
          <BaseAccordion question='Лучшее время года для путешествий по Таймыру'>
            <article className='home-faq__answer'>
              <p>
                Путешествовать по Таймыру можно круглый год! Ответ на этот вопрос во многом зависит от ваших
                предпочтений.
              </p>
              <p>
                Если вы хотите посетить плато Путорана или плато Анабар, сплавиться по местным рекам и увидеть как
                солнце светит не уходит за горизонт, 15 июля-15 августа — ваш выбор!
              </p>
              <p>
                Зимой можно посетить этностойбище с северными оленями, весной покататься на снегоходах, лыжах или
                сноубордах, а осенью с комфортом без комаров любоваться рыжей тундрой!
              </p>
            </article>
          </BaseAccordion>
          <BaseAccordion question='Что взять с собой в путешествие на Таймыр'>
            <article className='home-faq__answer'>
              <p>
                Документы — достаточно взять с собой стандартный набор документов. Для иностранных граждан необходимо
                разрешение ФСБ, для этого необходимо оформить{' '}
                <a
                  href='https://www.norilsk-city.ru/38341/index.shtml'
                  target='_blank'
                  rel='noreferrer'
                  style={{ textDecoration: 'underline', color: '#db2947' }}
                >
                  заявление
                </a>
                . Если вы планируете маршрут через территорию заповедника, необходимо заранее связаться с администрацией
                ФГБУ «Заповедники Таймыра»
              </p>
              <p>
                Спортивное снаряжение — весной начинается сезон лыж и сноубордов, местные горы не оставят вас
                равнодушными!
              </p>
              <p>
                Теплая и ветрозащитная одежда — в тундре часто прохладно и дует ветер, поэтому необходимо подготовиться
                и взять с собой подходящую одежду!
              </p>
              <p>Хороший настрой на незабываемое путешествие!</p>
            </article>
          </BaseAccordion>
          <BaseAccordion question='Когда можно увидеть северное сияние'>
            <article className='home-faq__answer'>
              <p>
                Самый вероятный сезон северного сияния — период с осеннего по весеннее равноденствие, то есть с сентября
                по март. Это не значит, что небо озаряется только в эти месяцы — сияние вполне может случиться и в
                августе. Но вероятность все-таки выше зимой, примерно с ноября по февраль.
              </p>
            </article>
          </BaseAccordion>
          <BaseAccordion question='Где понаблюдать за птицами, северными оленями, овцебыками'>
            <article className='home-faq__answer'>
              <p>
                Наблюдение за птицами — на Таймыре сотни уникальных видов птиц, они встречаются в разных уголках
                таймырской тундры, достаточно выйти за городскую территорию.
              </p>
              <p>
                Северные олени — посетить этностойбище, пожалуй, самый легкий способ увидеть гигантское стадо оленей и
                покататься в санях! Дикие северные олени чаще встречаются в отдаленных местах Таймыра.
              </p>
              <p>
                Овцебыки — обитают на территории арктического побережья, на севере Таймыра, их можно увидеть во время
                экспедиций!
              </p>
            </article>
          </BaseAccordion>
        </div>
      </div>
    </section>
  );
};
