import { SimpleForm, TextInput, required, Labeled } from 'react-admin';
import { LOCATIONS3D_FIELDS } from '../../../shared/constants/const';
import { CesiumMapField } from '../CesiumMapField/CesiumMapField';
import TinyMCE from '../TinyMCE';

const Locations3dForm = () => {
  return (
    <SimpleForm>
      <Labeled label={LOCATIONS3D_FIELDS.title} fullWidth>
        <TextInput source='name' validate={[required()]} label='' />
      </Labeled>
      <CesiumMapField />
      <Labeled label={LOCATIONS3D_FIELDS.description} fullWidth>
        <TinyMCE property='description' required />
      </Labeled>
    </SimpleForm>
  );
};
export default Locations3dForm;
