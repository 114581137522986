import { MutableRefObject, useRef } from 'react';
import { Cartesian3, defined, Viewer as TViewer } from 'cesium';
import { ScreenSpaceEventHandler, ScreenSpaceEvent, CesiumComponentRef } from 'resium';
import { KeyboardEventModifiers, ScreenSpaceEventTypes } from 'shared/constants/const';
import { TMapEvent, TPicked } from 'shared/types/cesiumMap.types';

type TCesiumMapEvents = {
  viewerRef: MutableRefObject<CesiumComponentRef<TViewer> | null>;
  handleClickScreen: (event: TMapEvent) => void;
};

export const CesiumMapEvents = ({ viewerRef, handleClickScreen }: TCesiumMapEvents) => {
  const centerRotateRef = useRef<Cartesian3 | null>(null);

  const handleRotateCamera = (event: TMapEvent) => {
    const viewer = viewerRef.current?.cesiumElement;
    const centerRotate = centerRotateRef.current;
    const isRotateEvent = 'startPosition' in event || 'angleAndHeight' in event;
    if (!viewer || !centerRotate || !isRotateEvent) return;
    const startPosition = 'startPosition' in event ? event.startPosition : event.angleAndHeight.endPosition;
    const endPosition = 'startPosition' in event ? event.endPosition : event.angleAndHeight.startPosition;
    const { canvas, camera } = viewer;
    const x = endPosition.x - startPosition.x;
    const rotateFactor = 'startPosition' in event ? 1 / canvas.width : 1;
    camera.rotate(centerRotate, x * rotateFactor);
  };

  const enableRotateCamera = (event: TMapEvent) => {
    const viewer = viewerRef.current?.cesiumElement;
    const isTouchOrClickEvent = 'position' in event || 'position1' in event;
    if (!viewer || !isTouchOrClickEvent) return;
    const position = 'position' in event ? event.position : event.position1;
    const picked: TPicked = viewer.scene.pick(position);
    if (!defined(picked)) return;
    centerRotateRef.current = viewer.scene.pickPosition(position);
  };

  const disableRotateCamera = () => {
    centerRotateRef.current = null;
  };

  const toggleMoveCamera = (event: TMapEvent) => {
    const position = 'position' in event ? event.position : null;
    const viewer = viewerRef.current?.cesiumElement;
    if (!viewer || !position) return;
    const picked: TPicked = viewer.scene.pick(position);
    viewer.scene.screenSpaceCameraController.enableRotate = !!defined(picked);
  };

  return (
    <ScreenSpaceEventHandler>
      <ScreenSpaceEvent action={handleClickScreen} type={ScreenSpaceEventTypes.LEFT_CLICK} />
      <ScreenSpaceEvent action={toggleMoveCamera} type={ScreenSpaceEventTypes.LEFT_DOWN} />
      <ScreenSpaceEvent action={toggleMoveCamera} type={ScreenSpaceEventTypes.RIGHT_DOWN} />

      <ScreenSpaceEvent action={enableRotateCamera} type={ScreenSpaceEventTypes.MIDDLE_DOWN} />
      <ScreenSpaceEvent
        action={enableRotateCamera}
        type={ScreenSpaceEventTypes.LEFT_DOWN}
        modifier={KeyboardEventModifiers.CTRL}
      />
      <ScreenSpaceEvent action={enableRotateCamera} type={ScreenSpaceEventTypes.PINCH_START} />

      <ScreenSpaceEvent action={handleRotateCamera} type={ScreenSpaceEventTypes.MOUSE_MOVE} />
      <ScreenSpaceEvent
        action={handleRotateCamera}
        type={ScreenSpaceEventTypes.MOUSE_MOVE}
        modifier={KeyboardEventModifiers.CTRL}
      />
      <ScreenSpaceEvent action={handleRotateCamera} type={ScreenSpaceEventTypes.PINCH_MOVE} />

      <ScreenSpaceEvent action={disableRotateCamera} type={ScreenSpaceEventTypes.MIDDLE_UP} />
      <ScreenSpaceEvent action={disableRotateCamera} type={ScreenSpaceEventTypes.LEFT_UP} />
      <ScreenSpaceEvent
        action={disableRotateCamera}
        type={ScreenSpaceEventTypes.LEFT_UP}
        modifier={KeyboardEventModifiers.CTRL}
      />
      <ScreenSpaceEvent action={disableRotateCamera} type={ScreenSpaceEventTypes.PINCH_END} />
    </ScreenSpaceEventHandler>
  );
};
