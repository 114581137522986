import { Helmet } from 'react-helmet';
import MobileBottom from 'components/MobileBottom/MobileBottom';
import CesiumMap from 'components/CesiumMap/CesiumMap';
import AlternativeHeader from 'components/Header/AlternativeHeader';

const Map3DPage = () => {
  return (
    <>
      <Helmet>
        <title>3D Карта - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <section className='ready-tours' style={{ height: '100vh' }}>
        <AlternativeHeader />
        <div className='tours__content cesium-map'>
          <CesiumMap typeClick='showInfo' />
        </div>
        <MobileBottom />
      </section>
    </>
  );
};

export default Map3DPage;
