import { MutableRefObject } from 'react';
import {
  Cartesian3,
  Viewer,
  Entity,
  ConstantProperty,
  Cartographic,
  NearFarScalar,
  Math as CesiumMath,
  Camera,
  Rectangle,
  // Color,
} from 'cesium';
import { TCesium3DTileset } from 'shared/types/cesiumMap.types';
import marker from 'assets/images/3dmap/marker.png';

export const initCamera = (camera: Camera, isSetCamera: MutableRefObject<boolean>) => {
  if (!isSetCamera.current) {
    isSetCamera.current = true;
    camera.setView({
      //norilsk2035
      destination: Cartesian3.fromDegrees(88.215929, 69.34416, 400),
      // destination: Cartesian3.fromDegrees(86.17, 69.404768, 400),
      orientation: {
        heading: CesiumMath.toRadians(90),
        pitch: CesiumMath.toRadians(-55),
        roll: CesiumMath.toRadians(0),
      },
    });
  }
};

const getFactorsRotatedRectangle = (heading: number, rectangle: Rectangle, canvas: HTMLCanvasElement) => {
  const sin = Math.sin(heading);
  const cos = Math.cos(heading);
  const widthRotatedCanvas = canvas.width * Math.abs(cos) + canvas.height * Math.abs(sin);
  const heightRotatedCanvas = canvas.width * Math.abs(sin) + canvas.height * Math.abs(cos);
  const pixelFactorLon = rectangle.width / widthRotatedCanvas;
  const pixelFactorLat = rectangle.height / heightRotatedCanvas;
  const smallSize = canvas.width > canvas.height ? canvas.height : canvas.width;
  const offsetDegree = CesiumMath.toRadians(18);
  const degreeLeft = Math.abs(heading - offsetDegree);
  const degreeRight = Math.abs(heading + offsetDegree);

  let dWest = 0;
  let dSouth = 0;
  let dEast = 0;
  let dNorth = 0;

  if (heading < Math.PI * 0.25) {
    dWest = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLat;
  } else if (heading < Math.PI * 0.5) {
    dWest = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
  } else if (heading < Math.PI * 0.75) {
    dWest = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
  } else if (heading < Math.PI) {
    dWest = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
  } else if (heading < Math.PI * 1.25) {
    dWest = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
  } else if (heading < Math.PI * 1.5) {
    dWest = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
  } else if (heading < Math.PI * 1.75) {
    dWest = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * sin * pixelFactorLat;
  } else if (heading < Math.PI * 2) {
    dWest = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLon;
    dSouth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * sin * pixelFactorLat;
    dEast = smallSize * Math.sin(degreeRight) * Math.cos(degreeRight) * cos * pixelFactorLon;
    dNorth = smallSize * Math.sin(degreeLeft) * Math.cos(degreeLeft) * cos * pixelFactorLat;
  }
  return { dWest, dSouth, dEast, dNorth };
};

const initBoundaryRectangle = () => {
  const coordsCartographicArray = [
    { longitude: 1.503360317621365, latitude: 1.2115922307235606, height: 29.621801477456923 },
    { longitude: 1.505168703844379, latitude: 1.2115922307235606, height: 35.02361334293514 },
    { longitude: 1.505168703844379, latitude: 1.211061269288941, height: 34.580495376696526 },
    { longitude: 1.503360317621365, latitude: 1.211061269288941, height: 27.442719267017555 },
  ];
  const cartographicArray = coordsCartographicArray.map(
    ({ longitude, latitude, height }) => new Cartographic(longitude, latitude, height)
  );
  const { west, south, east, north } = Rectangle.fromCartographicArray(cartographicArray);
  return (heading: number, viewRectangle: Rectangle, canvas: HTMLCanvasElement) => {
    const { dWest, dSouth, dEast, dNorth } = getFactorsRotatedRectangle(heading, viewRectangle, canvas);
    return {
      minWest: west - Math.abs(dWest),
      minSouth: south - Math.abs(dSouth),
      maxEast: east + Math.abs(dEast),
      maxNorth: north + Math.abs(dNorth),
    };
  };
};

// export const showCameraRectangle = (viewer: Viewer) => {
//   const viewRectangle = viewer.camera.computeViewRectangle();
//   // viewer.camera.setView({
//   //   destination: viewRectangle,
//   // });
//   viewer.entities.add({
//     rectangle: {
//       coordinates: viewRectangle,
//       height: 100,
//       fill: false,
//       outline: true,
//       outlineColor: Color.WHITE,
//     },
//   });
// };

export const setMarker = (viewer: Viewer, primitive: TCesium3DTileset) => {
  const entities = viewer.entities;
  if (!primitive?.boundingSphere?.center) return;
  const cartographic = Cartographic.fromCartesian(primitive.boundingSphere.center);
  const surface = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height);
  const ellipsoid = viewer.scene.globe.ellipsoid;
  const xyz = new Cartesian3(surface.x, surface.y, surface.z);
  const wgs84 = ellipsoid.cartesianToCartographic(xyz);
  const longitude = CesiumMath.toDegrees(wgs84.longitude);
  const latitude = CesiumMath.toDegrees(wgs84.latitude);
  const height = wgs84.height + 20;
  const coords = Cartesian3.fromDegrees(longitude, latitude, height);
  const optionEntity = {
    name: primitive.name || 'Новый объект',
    description: primitive.description || '',
    position: coords,
    billboard: {
      image: marker,
      scaleByDistance: new NearFarScalar(100, 2, 1500, 0.5),
    },
  };
  const newEntity = new Entity(optionEntity);
  entities.add(newEntity);
  return newEntity;
};

export const removeMarker = (viewer: Viewer, entity: Entity | null) => {
  if (entity) {
    viewer.entities.remove(entity);
  }
};

export const showInfo = (viewer: Viewer, tileset: TCesium3DTileset, entity: Entity) => {
  if (!tileset.name && !tileset.description) return;
  const property = new ConstantProperty(tileset.description || '');
  entity.name = tileset.name || '';
  entity.description = property;
  viewer.selectedEntity = entity;
};

export const getBoundaryCesiumMap = initBoundaryRectangle();
