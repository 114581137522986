import { ReactComponent as BuildingIcon } from 'assets/images/trk/building.svg';
import { ReactComponent as KpiIndicatorIcon } from 'assets/images/trk/kpi-indicator.svg';
import { ReactComponent as GroupIcon } from 'assets/images/trk/group.svg';
import { ReactComponent as OperatorIcon } from 'assets/images/trk/operator.svg';
import { ReactComponent as PlacesIcon } from 'assets/images/trk/places.svg';
import { ReactComponent as ProductsIcon } from 'assets/images/trk/products.svg';
import { ReactComponent as ProjectsIcon } from 'assets/images/trk/products.svg';
import React, { useEffect, useState } from 'react';
import { ClusterItemCard, TClusterItem } from './ClusterItemCard';
import { ParticipantInfosCard } from './ParticipantInfosCard';
import { TDocFetch } from '../../../../shared/types/common.types';
import CommonService from '../../../../services/common.service';
import { handleError } from '../../../../http/handleError';
import Notification from '../../../../shared/lib/notification';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsBecomeMemberOpen,
  selectTapeParticipants,
  setIsBecomeMemberOpen
} from '../../../../manageStore/common/common.slice';
import {getPublishedNews} from "../../../../manageStore/news/newsSlice";
import {fetchTapeParticipants} from "../../../../manageStore/common/common.thunk";

const clusterItems: TClusterItem[] = [
  {
    id: 1,
    label: (
      <span>
        туристов из России <br /> и зарубежья
      </span>
    ),
    count: '10 000+',
    icon: <GroupIcon className='metrics-content__grid__item__icon' />,
  },
  {
    id: 2,
    label: (
      <span>
        действующих <br /> туроператоров
      </span>
    ),
    count: '8',
    icon: <OperatorIcon className='metrics-content__grid__item__icon' />,
  },
  {
    id: 3,
    label: (
      <span>
        профессиональных <br /> турагенств
      </span>
    ),
    count: '16',
    icon: <ProjectsIcon className='metrics-content__grid__item__icon' />,
  },
  {
    id: 4,
    label: <span>квалифицированных средств размещения гостей кластера</span>,
    count: '11',
    icon: <BuildingIcon className='metrics-content__grid__item__icon' />,
  },
  {
    id: 5,
    label: <span>интересных туристических продукта</span>,
    count: '64',
    icon: <ProductsIcon className='metrics-content__grid__item__icon' />,
  },
  {
    id: 6,
    label: <span>культурно-исторических объектов</span>,
    count: '158',
    icon:  <PlacesIcon className='metrics-content__grid__item__icon' />,
  },
];

export const ClusterMetrics = () => {
  const dispatch = useDispatch();
  const [docs, setDocs] = useState<TDocFetch[]>([]);
  const isOpen = useSelector(selectIsBecomeMemberOpen);

  const handleSelectYear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;

    if (button) {
      const currentYear = button.getAttribute('data-year');

      if (currentYear) {
        const yearToSetAsActive = document.getElementsByClassName(currentYear);
        yearToSetAsActive[0].classList.remove('active-year');
        yearToSetAsActive[0].classList.add('not-active-year');
      }

      const nodeList = Array.from(document.getElementsByClassName('metrics-content__chart__container__item'));
      if (nodeList.length) {
        nodeList.forEach((node) => {
          if (node.classList.contains(currentYear!)) {
            node.classList.remove('not-active-year');
            node.classList.add('active-year');
          } else {
            node.classList.remove('active-year');
            node.classList.add('not-active-year');
          }
        });
      }
    }
  };

  const handelPortal = () => {
    dispatch(setIsBecomeMemberOpen(!isOpen));
  };

  useEffect(() => {
    const getDocs = async () => {
      try {
        const { data } = await CommonService.getNpaDocs();
        setDocs(data);
      } catch (e) {
        const errorMessage = handleError(e);
        Notification.error(errorMessage);
        throw e;
      }
    };
    getDocs();
  }, []);



  return (
    <div className='container'>
      <h1 className='metrics-content__title'>MЕТРИКИ КЛАСТЕРА</h1>

      <div className='metrics-content'>
        <div className='metrics-content__first'>
          <div className='metrics-content__first__header'>
            <span className='metrics-content__first__label'>Количество туристов в год</span>
            <span className='metrics-content__first__count'>от 2 500 чел.</span>
            <span className='line-sep line-sep__top' />
          </div>

          <div className='metrics-content__chart'>
            <div className='metrics-content__chart__container'>
              <div className='metrics-content__chart__container__item first-year active-year'>
                <div className='first-year__indicator'>
                  <KpiIndicatorIcon />
                </div>
                <div></div>
                <button onClick={handleSelectYear} data-year='first-year' type='button'>
                  2019
                </button>
              </div>

              <div className='metrics-content__chart__container__item second-year not-active-year'>
                <div className='second-year__indicator'>
                  <KpiIndicatorIcon />
                </div>
                <div></div>
                <button onClick={handleSelectYear} data-year='second-year' type='button'>
                  2020
                </button>
              </div>

              <div className='metrics-content__chart__container__item third-year not-active-year'>
                <div className='third-year__indicator'>
                  <KpiIndicatorIcon />
                </div>
                <div></div>
                <button onClick={handleSelectYear} data-year='third-year'>
                  2021
                </button>
              </div>
            </div>
          </div>

          <div className='metrics-content__first__note'>
            <span className='line-sep line-sep__bottom' />
            <p>
              Посещаемость ТРК «Арктический» <br />
              за текущий и предыдущие годы
            </p>
          </div>
        </div>

        <div className='metrics-content__grid'>
          {clusterItems.map((clusterItem) => (
            <ClusterItemCard clusterItem={clusterItem} key={clusterItem.id} />
          ))}
        </div>
      </div>
      <div>
        <button onClick={handelPortal} className='metrics-content__become-member btn'>
          стать участником
        </button>
      </div>

      <div className='metrics-content__contact'>
        <p>Если у вас остались вопросы, свяжитесь с нами</p>
        <a href='tel:+7 (3919) 456-811'>+7 (3919) 456-811</a>
        <a href='mailto:vopros@mb24.ru'>vopros@mb24.ru</a>
      </div>

      {docs.length > 0 && <div className='participants-infos'>
        <h1 className='participants-infos__title'>Информация для участников</h1>

        <ul className='participants-infos__list'>
          {docs.map((info, index) => (
              <ParticipantInfosCard key={index} doc={info} />
          ))}
        </ul>
      </div>}

    </div>
  );
};
