import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store';
import { TFiltersPartners } from 'shared/types/partner.types';

export const selectLoading = (state: TRootState) => state.partners.isLoading;
export const selectPartners = (state: TRootState) => state.partners.partners;
export const selectFiltersPartners = (state: TRootState) => state.partners.filtersPartners;

export const selectFilteredPartners = createSelector(
  selectPartners,
  (state: TRootState, filters: TFiltersPartners) => filters,
  (partners, filters) => {
    if (!filters.types.length) {
      return partners;
    }
    return partners?.filter((partner) => filters.types.includes(partner.tag));
  }
);

export const selectPartnerItem = createSelector(
  selectPartners,
  (state: TRootState, id: string) => id,
  (partners, id) => {
    const idNumber = Number(id);
    return idNumber && partners ? partners.find((partner) => partner.id === idNumber) : null;
  }
);
