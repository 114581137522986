import ReactDom from 'react-dom';
import parse from 'html-react-parser';
import { ReactNode } from 'react';
import classNames from 'classnames';

type TPortalProps = {
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  styles?: Record<string, string>;
  slider?: boolean;
  title?: string;
  className?: string;
  classHeader?: string;
};

const Portal = ({ children, isOpen, onClose, styles, slider, title, className, classHeader }: TPortalProps) => {
  const portalElement = document.querySelector('#portal');
  if (!portalElement) {
    return null;
  }

  return ReactDom.createPortal(
    isOpen ? (
      <>
        <div className='overlay' onClick={onClose}></div>
        <section
          className={classNames('portal-wrapper', { 'portal-wrapper_slider': slider }, className)}
          style={styles}
        >
          <div className={classNames('portal-header', classHeader)}>
            {title && <h1 className='portal-title'>{title}</h1>}
            {onClose && <button className='portal-close' onClick={onClose} />}
          </div>
          {typeof children === 'string' ? parse(children) : children}
        </section>
      </>
    ) : null,
    portalElement
  );
};

export default Portal;
