import nordStar from 'assets/images/banner/nordstar.png';
import nordStarMobile from 'assets/images/banner/nordstar_mobile.png';
import { useMediaQuery } from 'shared/hooks/useMatchMedia';

export const HomeBanner = () => {
  const [isMobile] = useMediaQuery(['(max-width: 768px)']);

  return (
    <section className='home-banner'>
      <div className='container home-banner__container'>
        <img src={isMobile ? nordStarMobile : nordStar} alt='NordStar авиа-рейсы' />
      </div>
    </section>
  );
};
