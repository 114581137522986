import React from 'react';
import {
  SimpleForm,
  TextInput,
  required,
  Labeled,
  PasswordInput,
  minLength,
  email,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import CheckBoxControl from '../CheckBoxControl';
import { USERS_FIELDS, TRANSFORM_TEXT } from '../../../shared/constants/const';

const checkPassword = () => (value, allValues, props) => {
  return value === allValues.password ? undefined : 'Пароли должны совпадать';
};
const validateLogin = [required()];
const validateEmail = [required(), email()];
const validatePassword = [required(), minLength(6)];
const validatePassword2 = [required(), minLength(6), checkPassword()];

const PostEditToolbar = (props) => {
  const record = useRecordContext();
  const userData = useSelector((state) => state.user.userData);
  const { superAdmin, admin } = TRANSFORM_TEXT.role;
  const isDeleteButton = userData?.role === superAdmin || (record && record.role !== admin);
  return (
    <Toolbar {...props} sx={{ justifyContent: 'space-between' }}>
      <SaveButton />
      {isDeleteButton && <DeleteButton />}
    </Toolbar>
  );
};

const UserForm = ({ isCreate }) => (
  <SimpleForm toolbar={<PostEditToolbar />}>
    <Labeled label={USERS_FIELDS.login} fullWidth>
      <TextInput source="login" validate={validateLogin} label="" />
    </Labeled>
    {isCreate && (
      <>
        <Labeled label={USERS_FIELDS.email} fullWidth>
          <TextInput source="email" label="" validate={validateEmail} />
        </Labeled>
        <Labeled label={USERS_FIELDS.password} fullWidth>
          <PasswordInput source="password" validate={validatePassword} label="" />
        </Labeled>
        <Labeled label={USERS_FIELDS.password2} fullWidth>
          <PasswordInput source="password2" validate={validatePassword2} label="" />
        </Labeled>
        <Labeled label={USERS_FIELDS.role} fullWidth>
          <Controller
            name="role"
            render={({ field }) => {
              return <TextField {...field} value={TRANSFORM_TEXT.role.admin} disabled />;
            }}
          />
        </Labeled>
        <CheckBoxControl name="policy" label="Согласие на обработку данных" disabled={true} />
      </>
    )}
  </SimpleForm>
);

export default UserForm;
