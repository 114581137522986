import { useEffect, useRef } from 'react';
import Loading from 'components/Loading/Loading';
import { Camera, ScreenSpaceCameraController, Sun, Viewer, CesiumComponentRef } from 'resium';
import { Ion, Viewer as TViewer, Entity as TEntity, defined } from 'cesium';
import { TMapEvent, TCesium3DTileset, TPicked } from 'shared/types/cesiumMap.types';
import {
  //getBoundaryCesiumMap,
  initCamera,
  removeMarker,
  setMarker,
  // showCameraRectangle,
  showInfo,
} from 'shared/utils/managerCesiumMap';
import { CesiumMapToolbar } from 'components/CesiumMapToolbar/CesiumMapToolbar';
import {
  selectAssetsCesium,
  selectIsLoadingCesium,
  selectTilesetsDataCesium,
  selectTokenCesium,
} from 'manageStore/cesium/cesium.select';
import { TilesetsList } from './TilesetsList';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { getCesiumDataThunk } from 'manageStore/cesium/cesium.thunk';
import { CesiumMapEvents } from './CesiumMapEvents';

type TCesiumMapProps = {
  typeClick?: 'showInfo' | 'setMarker';
  setIdFieldForm?: (id: number) => void;
  idTileset?: number;
  isOnce?: boolean;
  defaultHistory?: boolean;
};

const CesiumMap = ({ typeClick, setIdFieldForm, idTileset, isOnce, defaultHistory }: TCesiumMapProps) => {
  const dispatch = useAppDispatch();
  const isLoading = selectIsLoadingCesium();
  const token = selectTokenCesium();
  const assets = selectAssetsCesium();
  const tilesetsData = selectTilesetsDataCesium();

  const viewerRef = useRef<CesiumComponentRef<TViewer> | null>(null);
  const entityRef = useRef(new TEntity());
  const addedEntityRef = useRef<TEntity | null>(null);
  const isSetCamera = useRef(false);

  const handleSetMarker = (viewer: TViewer, tileset: TCesium3DTileset, id?: number) => {
    const newEntity = setMarker(viewer, tileset);
    if (newEntity && id) {
      removeMarker(viewer, addedEntityRef.current);
      addedEntityRef.current = newEntity;
      if (setIdFieldForm) {
        setIdFieldForm(id);
      }
    }
  };

  const setTilestInfo = (tileset: TCesium3DTileset, id: number) => {
    const tilesetData = tilesetsData.find((data) => data.cesiumId === id);
    if (tilesetData) {
      tileset.description = tilesetData.description;
      tileset.name = tilesetData.name;
    }
    return !!tilesetData;
  };

  const handleTilesetLoad = (tileset: TCesium3DTileset, id: number) => {
    const viewer = viewerRef.current?.cesiumElement;
    if (!viewer) return;
    initCamera(viewer.camera, isSetCamera);
    tileset.id = id;
    const isInfo = setTilestInfo(tileset, id);
    if (!isInfo) return;
    if (isOnce) {
      if (idTileset === id) {
        handleSetMarker(viewer, tileset, id);
      }
    } else if (!idTileset || idTileset !== id) {
      handleSetMarker(viewer, tileset);
    } else if (idTileset === id) {
      handleSetMarker(viewer, tileset, id);
    }
  };

  // const getPoint = (event: TMapEvent) => {
  //   const position = 'position' in event ? event.position : null;
  //   const viewer = viewerRef.current?.cesiumElement;
  //   if (!viewer || !position) return;
  //   const earthPosition = viewer.scene.pickPosition(position);
  //   console.log(Cartographic.fromCartesian(earthPosition));
  // };

  const handleClickScreen = (event: TMapEvent) => {
    // getPoint(event);
    const position = 'position' in event ? event.position : null;
    const viewer = viewerRef.current?.cesiumElement;
    if (!viewer || !position || !typeClick) return;
    // showCameraRectangle(viewer);
    const picked: TPicked = viewer.scene.pick(position);
    if (!defined(picked)) return;
    const id = picked.primitive.id;
    if (!id) return;
    switch (typeClick) {
      case 'showInfo':
        showInfo(viewer, picked.primitive, entityRef.current);
        break;
      case 'setMarker':
        handleSetMarker(viewer, picked.primitive, id);
        break;
    }
  };

  const handleCameraChange = () => {
    // const viewer = viewerRef.current?.cesiumElement;
    // if (!viewer) return;
    // viewer.scene.debugShowFramesPerSecond = true;
    // const { camera, canvas } = viewer;
    // const viewRectangle = camera.computeViewRectangle();
    // if (!viewRectangle) return;
    // const { heading } = camera;
    // const { west, north, east, south } = viewRectangle;
    // const { minWest, minSouth, maxEast, maxNorth } = getBoundaryCesiumMap(heading, viewRectangle, canvas);
    // if (west < minWest) {
    //   // console.log('west');
    //   const { latitude, longitude, height } = camera.positionCartographic;
    //   const lon = longitude + minWest - west;
    //   camera.position = Cartesian3.fromRadians(lon, latitude, height);
    // }
    // if (east > maxEast) {
    //   // console.log('east');
    //   const { latitude, longitude, height } = camera.positionCartographic;
    //   const lon = longitude + maxEast - east;
    //   camera.position = Cartesian3.fromRadians(lon, latitude, height);
    // }
    // if (north > maxNorth) {
    //   // console.log('north');
    //   const { latitude, longitude, height } = camera.positionCartographic;
    //   const lat = latitude + maxNorth - north;
    //   camera.position = Cartesian3.fromRadians(longitude, lat, height);
    // }
    // if (south < minSouth) {
    //   // console.log('south');
    //   const { latitude, longitude, height } = camera.positionCartographic;
    //   const lat = latitude + minSouth - south;
    //   camera.position = Cartesian3.fromRadians(longitude, lat, height);
    // }
  };

  useEffect(() => {
    Ion.defaultAccessToken = token.value;
    const viewer = viewerRef.current?.cesiumElement;
    if (viewer) {
      viewer.entities.removeAll();
    }
  }, [token]);

  useEffect(() => {
    dispatch(getCesiumDataThunk(!!defaultHistory));
  }, []);

  return (
    <Viewer
      requestRenderMode={true}
      maximumRenderTimeChange={Infinity}
      scene3DOnly={true}
      selectionIndicator={false}
      skyBox={false}
      fullscreenButton={false}
      homeButton={false}
      timeline={false}
      animation={false}
      geocoder={false}
      baseLayerPicker={false}
      sceneModePicker={false}
      infoBox={true}
      resolutionScale={0.8}
      ref={viewerRef}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <CesiumMapToolbar />
      <Camera percentageChanged={0.01} onChange={handleCameraChange} />
      <Sun show={false} />
      <ScreenSpaceCameraController
        maximumZoomDistance={700}
        minimumZoomDistance={200}
        inertiaSpin={0}
        inertiaTranslate={0}
        inertiaZoom={0}
        enableTilt={false}
      />
      {isLoading && (
        <div className='cesium-loading'>
          <Loading />
        </div>
      )}
      {!isLoading && !!assets.length && <TilesetsList assets={assets} handleTilesetLoad={handleTilesetLoad} />}
      <CesiumMapEvents viewerRef={viewerRef} handleClickScreen={handleClickScreen} />
    </Viewer>
  );
};

export default CesiumMap;
