import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { registrationOperator } from 'manageStore/user/userSlice';

type TRegFormTouroperatorProps = {
  typeUser: string;
  setOpenPortal: (isOpen: boolean) => void;
};

const RegFormTouroperator = ({ typeUser, setOpenPortal }: TRegFormTouroperatorProps) => {
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);
  const formik = useFormik({
    initialValues: {
      type: typeUser,
      registryNumber: '',
      fullName: '',
      shortName: '',
      address: '',
      ogrn: '',
      inn: '',
      phone: '',
      email: '',
      contactEmail: '',
      contactLastName: '',
      contactFirstName: '',
      contactPatronymic: '',
      contactPhone: '',
      policy: false,
      personal: false,
    },
    validationSchema: Yup.object({
      registryNumber: Yup.string().required('Заполните поле'),
      fullName: Yup.string().required('Заполните поле'),
      shortName: Yup.string().required('Заполните поле'),
      address: Yup.string().required('Заполните поле'),
      ogrn: Yup.string().required('Заполните поле'),
      inn: Yup.string().required('Заполните поле'),
      phone: Yup.string().required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      contactEmail: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      contactLastName: Yup.string().required('Заполните поле'),
      contactFirstName: Yup.string().required('Заполните поле'),
      contactPatronymic: Yup.string().required('Заполните поле'),
      contactPhone: Yup.string().required('Заполните поле'),
      policy: Yup.boolean().oneOf([true], 'Политика должна быть принята'),
      personal: Yup.boolean().oneOf([true], 'Соглашение должно быть принято'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        await registrationOperator({ ...values });
        setOpenPortal(true);
      } catch (e) {
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <>
      <p className='registration-text'>Данные организации</p>
      <label>
        <input
          className='login-input'
          id='registryNumber'
          type='text'
          placeholder='Номер реестровой записи'
          {...formik.getFieldProps('registryNumber')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.registryNumber && formik.errors.registryNumber ? formik.errors.registryNumber : ''}
      </p>
      <label>
        <textarea
          className='login-input registration-input__textarea'
          id='fullName'
          placeholder='Полное наименование туроператора'
          {...formik.getFieldProps('fullName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='shortName'
          type='text'
          placeholder='Короткое название организации'
          {...formik.getFieldProps('shortName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.shortName && formik.errors.shortName ? formik.errors.shortName : ''}
      </p>
      <label>
        <textarea
          className='login-input registration-input__textarea'
          id='address'
          placeholder='Адрес'
          {...formik.getFieldProps('address')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.address && formik.errors.address ? formik.errors.address : ''}</p>
      <label>
        <input
          className='login-input registration-input__textarea'
          id='ogrn'
          type='text'
          placeholder='ОГРН'
          {...formik.getFieldProps('ogrn')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.ogrn && formik.errors.ogrn ? formik.errors.ogrn : ''}</p>
      <label>
        <input className='login-input' id='inn' type='text' placeholder='ИНН' {...formik.getFieldProps('inn')} />
      </label>
      <p className='login-form-error'>{formik.touched.inn && formik.errors.inn ? formik.errors.inn : ''}</p>
      <label>
        <input
          className='login-input'
          id='phone'
          type='text'
          placeholder='Телефон организации'
          {...formik.getFieldProps('phone')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.phone && formik.errors.phone ? formik.errors.phone : ''}</p>
      <label>
        <input
          className='login-input'
          id='email'
          type='email'
          placeholder='E-mail организации'
          {...formik.getFieldProps('email')}
        />
      </label>
      <p className='login-form-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>
      <p className='registration-text'>Контактное лицо</p>
      <label>
        <input
          className='login-input'
          id='contactFirstName'
          type='text'
          placeholder='Имя'
          {...formik.getFieldProps('contactFirstName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.contactFirstName && formik.errors.contactFirstName ? formik.errors.contactFirstName : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='contactLastName'
          type='text'
          placeholder='Фамилия'
          {...formik.getFieldProps('contactLastName')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.contactLastName && formik.errors.contactLastName ? formik.errors.contactLastName : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='contactPatronymic'
          type='text'
          placeholder='Отчество'
          {...formik.getFieldProps('contactPatronymic')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.contactPatronymic && formik.errors.contactPatronymic ? formik.errors.contactPatronymic : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='contactEmail'
          type='email'
          placeholder='Email'
          {...formik.getFieldProps('contactEmail')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.contactEmail && formik.errors.contactEmail ? formik.errors.contactEmail : ''}
      </p>
      <label>
        <input
          className='login-input'
          id='contactPhone'
          type='text'
          placeholder='Номер телефона'
          {...formik.getFieldProps('contactPhone')}
        />
      </label>
      <p className='login-form-error'>
        {formik.touched.contactPhone && formik.errors.contactPhone ? formik.errors.contactPhone : ''}
      </p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='policy' type='checkbox' {...formik.getFieldProps('policy')} />
        <span></span>
        <p>
          Согласен с{' '}
          <Link className='registration-policy-link' to='/privacy'>
            политикой конфиденциальности
          </Link>{' '}
          и{' '}
          <Link className='registration-policy-link' to='/useragreement'>
            пользовательским соглашением
          </Link>
        </p>
      </label>
      <p className='login-form-error'>{formik.touched.policy && formik.errors.policy ? formik.errors.policy : ''}</p>
      <label className='registration-label-policy'>
        <input className='registration-policy' id='personal' type='checkbox' {...formik.getFieldProps('personal')} />
        <span></span>
        <p>
          Согласен на{' '}
          <a className='registration-policy-link' href='/docs/personal_consent.pdf' target='_blank'>
            обработку персональных данных
          </a>
        </p>
      </label>
      <p className='login-form-error'>
        {formik.touched.personal && formik.errors.personal ? formik.errors.personal : ''}
      </p>
      <button
        className='login-form-btn btn'
        type='submit'
        disabled={saving}
        onClick={(event) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
      >
        Зарегистироваться
      </button>
    </>
  );
};

export default RegFormTouroperator;
