import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setFilters } from 'manageStore/tours/tours.slice';
import { selectFiltersTours, selectLoading, selectSortedTours } from 'manageStore/tours/tours.select';
import Loading from 'components/Loading/Loading';
import { TNamesTypeTourFilter } from 'shared/types/tours.types';
import { TourCard } from 'entities/tour/TourCard/TourCard';
import { ToursFilters } from 'features';

export const ToursMain = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const filters = useSelector(selectFiltersTours);
  const tours = useAppSelector((state) => selectSortedTours(state, filters));

  const changeFiltersTours = (type: string, fieldFilters: TNamesTypeTourFilter) => {
    if (!type) {
      dispatch(setFilters({ ...filters, types: [], locations: [] }));
      return;
    }
    const fieldValue = [...filters[fieldFilters]];
    if (filters[fieldFilters].includes(type)) {
      const typeIndex = fieldValue.findIndex((value) => value === type);
      fieldValue.splice(typeIndex, 1);
    } else {
      fieldValue.push(type);
    }
    dispatch(setFilters({ ...filters, [fieldFilters]: fieldValue }));
  };

  return (
    <main className='tours'>
      <div className='container tours__container'>
        <h1 className='tours__title'>Туры и экскурсии</h1>
        <ToursFilters filters={filters} changeFiltersTours={changeFiltersTours} withParams withFixed isMulti />
        {loading ? (
          <Loading />
        ) : tours?.length ? (
          <div className='tours__list-wrapper'>
            <div className='tours-list'>
              {tours.map((tour) => (
                <TourCard key={tour.id} tour={tour} />
              ))}
            </div>
          </div>
        ) : (
          <div className='no-results-text'>По выбранным параметрам туры пока отсутствуют.</div>
        )}
      </div>
    </main>
  );
};
