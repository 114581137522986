import { createAsyncThunk } from '@reduxjs/toolkit';
import Notification from 'shared/lib/notification';
import { handleError } from 'http/handleError';
import { cesiumActions } from './cesium.slice';
import CesiumMapService from 'services/cesiumMap.service';
import { TOKENS_CESIUM } from 'shared/constants/const';

export const getCesiumDataThunk = createAsyncThunk('getCesiumData', async (history: boolean, { dispatch }) => {
  const { setIsLoading, setHistory, setAssets, setTilesetsData } = cesiumActions;
  dispatch(setIsLoading(true));
  dispatch(setHistory(history));
  const token = history ? TOKENS_CESIUM.dudinka_history : TOKENS_CESIUM.dudinka;
  try {
    // const tokens = await CesiumMapService.getTokens(token);
    // console.log(tokens);
    const tilesetsData = await CesiumMapService.getTilesetsData();
    const assets = await CesiumMapService.getAssets(token);
    dispatch(setTilesetsData(tilesetsData.data));
    dispatch(setAssets(assets.data.assetIds));
  } catch (err) {
    Notification.error(handleError(err));
  } finally {
    dispatch(setIsLoading(false));
  }
});
