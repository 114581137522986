import { ReactComponent as ChartIcon } from 'assets/images/trk/trk-chart.svg';
import { TStepItem} from '../../../../../shared/types/aboutUs.types';
import { useMediaQuery } from '../../../../../shared/hooks/useMatchMedia';
import { StepsSectionDesktop } from './StepsSectionDesktop';
import {StepsSectionMobile} from "./StepsSectionMobile";

const stepsItems: TStepItem[] = [
  {
    id: 1,
    label: <p>Скачай и заполни заявление о вступлении</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: 'Заявление о вступлении',
    link: '/docs/statement.pdf',
    fileName: 'Заявление о вступлении'
  },
  {
    id: 2,
    label: <p>Скачай и заполни договор о присоединении</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: 'Договор о присоединении',
    link: '/docs/accession_agreement.docx',
    fileName: 'Договор о присоединении'
  },
  {
    id: 3,
    label: <p>Скачай и заполни анкету</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: 'Анкета',
    link: '/docs/data_collection_form.pdf',
    fileName: 'Анкета'
  },
  {
    id: 4,
    label: <p>Составь информационную справку о своей организации</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: 'Шаблон справки',
    link: '/docs/form.docx',
    fileName: 'Шаблон справки'
  },
  {
    id: 5,
    label: <p>Все подготовленные документы отправь на почту <br className='break-line'/>
      <a href='mailto:vopros@mb24.ru'>vopros@mb24.ru</a> с пометкой о вступлении в Кластер</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: null,
    link: null,
    fileName: ''
  },
  {
    id: 6,
    label: <p>Мы проверяем все данные и в течение <br/>
      10 рабочих дней ответим по твоей заявке</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: null,
    link: null,
    fileName: ''
  },
  {
    id: 7,
    label: <p>Дополнительно пройди регистрацию на платформе <br className='break-line' />
      <a href='https://xn--l1agf.xn--p1ai/' target='_blank'>МСП.РФ</a>, чтобы начать пользоваться преимуществами Кластера</p>,
    icon: <ChartIcon className='privileges-card__icon' />,
    btnIcon: <ChartIcon />,
    btnLabel: null,
    link: null,
    fileName: ''
  },
];

export const StepsSection = () => {
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  return (
    <section className='container privileges-content__first'>
      <h1 className='privileges-content__title'>Как стать участником <br/> ТРК "Арктический"</h1>
      <p className='privileges-content__desc'>7 простых шагов, чтобы начать пользоваться привилегиями Кластера</p>

      {isMobile ? <StepsSectionMobile stepsItems={stepsItems} /> : <StepsSectionDesktop stepsItems={stepsItems} />}
    </section>
  );
};
