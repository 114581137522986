import { selectHistoryCesium } from 'manageStore/cesium/cesium.select';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { getCesiumDataThunk } from 'manageStore/cesium/cesium.thunk';

export const CesiumMapToolbar = () => {
  const dispatch = useAppDispatch();
  const history = selectHistoryCesium();

  const changeHistory = () => {
    dispatch(getCesiumDataThunk(!history));
  };

  return (
    <div className='cesium-map__toolbar'>
      <ul className='tours__checkbox-list'>
        <li className='tours__checkbox-list__item'>
          <label className='tours__checkbox-label cesium__checkbox-label'>
            Вид
            <input type='checkbox' name='history' defaultChecked={history} onChange={changeHistory} />
            <span></span>
            <div>{history ? 'исторический' : 'современный'}</div>
          </label>
        </li>
      </ul>
      <div
        style={{
          bottom: '0px',
          position: 'absolute',
          textAlign: 'center',
          right: '0px',
          transform: 'translateY(100%)',
          minHeight: '40px',
          color: 'red',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        3D карты находятся в стадии разработки
      </div>
    </div>
  );
};
