import { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from 'manageStore/user/userSlice';
import { useMediaQuery } from '../../../shared/hooks/useMatchMedia';

type TLoginFormProps = {
  isAdmin: boolean;
};

const LoginForm = ({ isAdmin }: TLoginFormProps) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const handleSaving = () => setSaving((prev) => !prev);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const formik = useFormik({
    initialValues: isAdmin ? { email: '', password: '', isAdmin } : { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      password: Yup.string().required('Заполните поле'),
    }),
    onSubmit: async (values) => {
      handleSaving();
      try {
        dispatch(login(values));
      } catch (e) {
      } finally {
        handleSaving();
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className=''>
      <form className='login-form' onSubmit={formik.handleSubmit}>
        <label htmlFor='email_input'>
          <input
            className='login-input'
            id='email_input'
            type='email'
            placeholder='Укажите ваш email'
            {...formik.getFieldProps('email')}
          />
        </label>
        <p className='login-form-error'>{formik.touched.email && formik.errors.email ? formik.errors.email : ''}</p>
        <label htmlFor='password'>
          <input
            className='login-input'
            id='password'
            type='password'
            placeholder='Введите ваш пароль'
            {...formik.getFieldProps('password')}
          />
        </label>
        <p className='login-form-error'>
          {formik.touched.password && formik.errors.password ? formik.errors.password : ''}
        </p>

        <div className='login-forgot-block'>
          <Link className='login-forgot-password' to='/restore-pass'>
            Забыли пароль?
          </Link>
        </div>
        <div className=''>
          <button className='login-form-btn btn' type='submit' disabled={saving}>
            Войти в систему
          </button>
        </div>
        <div className='login-footer'>
          {isMobile ? (
            <div className='login-footer-row-mobile'>
              <span>У вас нет личного кабинета?&nbsp;</span>
              <Link className='register__link' to='/registration'>
                Зарегистрироваться
              </Link>
            </div>
          ) : (
            <div className='login-footer-row'>
              У вас нет аккуанта?&nbsp;
              <Link className='register__link' to='/registration'>
                Зарегистрироваться
              </Link>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
