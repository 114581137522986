export type TClusterItem = {
    id: number;
    label: JSX.Element;
    count: string;
    icon: JSX.Element | null;
};

type TProps = {
    clusterItem: TClusterItem
}
export const ClusterItemCard = ({clusterItem}: TProps)=> {
    return(
        <div key={clusterItem.id} className='metrics-content__grid__item'>
            <h4>{clusterItem.count}</h4>
            {clusterItem.icon && clusterItem.icon}
            <p>{clusterItem.label}</p>
        </div>
    )
}