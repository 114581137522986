import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import Loading from 'components/Loading/Loading';
import { TBlog } from 'shared/types/types';
import { SingleBlogBody } from './SingleBlogBody';
import BlogService from '../../services/blog.service';
import { useMediaQuery } from '../../shared/hooks/useMatchMedia';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

const SingleBlogPage = () => {
  const { id: blogId } = useParams<{ id: string }>();
  const [blog, setBlog] = useState<TBlog | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [hasAnyError, setAnyError] = useState(false);
  const [isMobile] = useMediaQuery(['(max-width: 900px)']);

  const loadBlogById = async () => {
    try {
      setAnyError((prev) => false);
      setLoading((prev) => true);
      const response = await BlogService.getBlogById(Number(blogId));
      if (!response.data.isPublished) {
        throw new Error('Статья неопубликованна');
      }
      setBlog(() => response.data);
      await BlogService.addViewBlog(Number(blogId));
      setLoading((prev) => false);
    } catch (e) {
      setAnyError(() => true);
      console.error(e);
    } finally {
      setLoading((prev) => false);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      await loadBlogById();
    };
    fetchDataAsync();
  }, [blogId]);

  const [scrolled, setScrolled] = useState(true);

  const handleScroll = () => {
    const needScroll = isMobile ? 50 : 500;
    if (window.scrollY > needScroll) {
      setScrolled(false);
    } else {
      setScrolled(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{blog ? `${blog.name} - ` : ''}Туркластер Арктический</title>
        <meta name='description' content={blog && blog.metaDesc ? blog.metaDesc : ''} />
        <meta name='keywords' content={blog && blog.metaKeywords ? blog.metaKeywords : ''} />
      </Helmet>
      <div className='content'>
        <AuthPortal />
        <Header isWhiteLink={scrolled} isFixed={true} />
        <section className='single-blog ornament ornament_big'>
          <article>{isLoading ? <Loading /> : <SingleBlogBody blog={blog} hasAnyError={hasAnyError} />}</article>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SingleBlogPage;
