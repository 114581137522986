import { Cesium3DTileset as TTileset, IonResource } from 'cesium';
import { Cesium3DTileset } from 'resium';

type TTilesetsListProps = {
  assets: never[];
  handleTilesetLoad: (tileset: TTileset, id: number) => void;
};

export const TilesetsList = ({ assets, handleTilesetLoad }: TTilesetsListProps) => {
  return (
    <>
      {assets.map((id) => (
        <Cesium3DTileset
          key={id}
          url={IonResource.fromAssetId(id)}
          maximumScreenSpaceError={10}
          // maximumMemoryUsage={0.01}
          skipLevelOfDetail
          immediatelyLoadDesiredLevelOfDetail
          onReady={(tileset) => handleTilesetLoad(tileset, id)}
        />
      ))}
    </>
  );
};
