import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { TFiltersTours, TNamesParamsTourFilter } from 'shared/types/tours.types';
import { TNamesTypeTourFilter } from 'shared/types/tours.types';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { defaultFiltersTours, setFilters } from 'manageStore/tours/tours.slice';
import { selectSortedTours } from 'manageStore/tours/tours.select';
import { ToursFiltersByType } from './ToursFiltersByType';
import { ButtonFilter, PortalFilter } from 'features/shared';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { ToursFiltersBySeason } from './ToursFiltersBySeason';
import { ToursFiltersByDuration } from './ToursFiltersByDuration';
import { ToursFiltersByPrice } from './ToursFiltersByPrice';

type TToursFiltersProps = {
  filters: TFiltersTours;
  changeFiltersTours: (type: string, fieldFilters: TNamesTypeTourFilter) => void;
  withParams?: boolean;
  withFixed?: boolean;
  isMulti?: boolean;
};

export const ToursFilters = ({ filters, changeFiltersTours, withParams, withFixed, isMulti }: TToursFiltersProps) => {
  const dispatch = useDispatch();
  const filtersRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(filtersRef);

  const [isOpenPortal, setOpenPortal] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);

  const tours = useAppSelector((state) => selectSortedTours(state, localFilters));

  const isActiveFiltersParams =
    !!filters.season.length ||
    filters.duration !== defaultFiltersTours.duration ||
    !(filters.price[0] === defaultFiltersTours.price[0] && filters.price[1] === defaultFiltersTours.price[1]);

  const isActiveClearButton =
    !!localFilters.season.length ||
    localFilters.duration !== defaultFiltersTours.duration ||
    !(localFilters.price[0] === defaultFiltersTours.price[0] && localFilters.price[1] === defaultFiltersTours.price[1]);

  const togglePortal = () => {
    if (isOpenPortal) {
      setLocalFilters(filters);
    }
    setOpenPortal((prev) => !prev);
  };

  const changeParamsTours = <TName extends TNamesParamsTourFilter>(name: TName, value: TFiltersTours[TName]) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearParamsFilters = () => {
    setLocalFilters(({ types }) => ({ ...defaultFiltersTours, types }));
  };

  const showTours = () => {
    togglePortal();
    dispatch(setFilters(localFilters));
  };

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  return (
    <>
      <div className={classNames('tours-filters', { 'tours-filters_full': !withParams })} ref={filtersRef}>
        <div className={classNames('tours-filters__container', { fixed: withFixed && !isOnScreen })}>
          <div className='tours-filters__content'>
            <div className='tours-filters__types-list'>
              <ToursFiltersByType filters={filters} clickFilter={changeFiltersTours} isMulti={isMulti} />
            </div>
            {withParams && <ButtonFilter openFilters={togglePortal} isActiveFiltersParams={isActiveFiltersParams} />}
          </div>
        </div>
      </div>
      {isOpenPortal && (
        <PortalFilter
          togglePortal={togglePortal}
          clearFilters={clearParamsFilters}
          applyFilters={showTours}
          countVariants={tours.length}
          isActiveClearButton={isActiveClearButton}
        >
          <ToursFiltersBySeason filters={localFilters.season} onClick={changeParamsTours} />
          <ToursFiltersByDuration filters={localFilters.duration} onClick={changeParamsTours} />
          <ToursFiltersByPrice filters={localFilters.price} onClick={changeParamsTours} />
        </PortalFilter>
      )}
    </>
  );
};
