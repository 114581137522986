import Slider, { Settings } from 'react-slick';
import { TitleSectionMain } from 'shared/lib/TitleSectionMain/TitleSectionMain';
import { TTravelGuideCityAdvantages } from 'shared/types/travelGuide.types';
import { URL_FOR_IMG } from 'shared/constants/const';

type TTravelGuideItemAdvantageProps = {
  advantages: TTravelGuideCityAdvantages[];
  afterColor: 'beige' | 'black' | 'blue';
};

const settingsSlider: Settings = {
  className: 'travel-guide-item-advantage__slider',
  dots: false,
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <></>,
  prevArrow: <></>,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1123,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 900,
      settings: { slidesToShow: 1 },
    },
  ],
};

export const TravelGuideItemAdvantage = ({ advantages, afterColor }: TTravelGuideItemAdvantageProps) => {
  return (
    <>
      <section className='travel-guide-item-advantage'>
        <div className='container travel-guide-item__container'>
          <TitleSectionMain title='Почему стоит посетить' className='travel-guide-item-advantage__title' />
          <Slider {...settingsSlider}>
            {advantages.map(({ id, photo, description, title }) => (
              <div className='travel-guide-item-advantage__group' key={id}>
                <div className='travel-guide-item-advantage__image'>
                  <img src={`${URL_FOR_IMG}/${photo.path}`} alt='advantage' />
                </div>
                <div className='travel-guide-item-advantage__content'>
                  <h3 className='travel-guide-item-advantage__name'>{title}</h3>
                  <div className='travel-guide-item-advantage__description'>{description}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <div className={`travel-guide-item-advantage__after ${afterColor}`}></div>
    </>
  );
};
