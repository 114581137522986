import { useState, useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import Layer from 'ol/layer/Layer';
import { createEmpty, extend } from 'ol/extent';
import { composeCssTransform } from 'ol/transform';
import Overlay from 'ol/Overlay';
import { Cluster } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { LineString } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';

import { generateClusters, generateOverlay, generatePath, transformCoords } from 'helpers/map.helper';
import { ReactComponent as BalloonArrow } from 'assets/images/map/balloon-arrow.svg';
import PointReadyTourBalloon from '../BalloonsMap/PointReadyTourBalloon';
import PointDesignBalloon from '../BalloonsMap/PointDesignBalloon';
import CityBalloon from '../BalloonsMap/CityBalloon';
import Svg from './Svg';
import { TCity, TLocation, TPointBrendMapDesigner } from 'shared/types/location.types';

type TSortedLocation = TLocation | (TCity & { items: TLocation[] });

type TBrendMap = {
  center?: number[];
  locations: TPointBrendMapDesigner[];
  routes: TPointBrendMapDesigner[] | TLocation[];
  handleClickMap?: () => void;
  showMore?: (location: TLocation) => void;
  showOsmMap?: (location: TCity) => void;
  addToOrder?: (loc: any) => void;
  admin?: boolean;
};

const BrendMap = ({
  center,
  locations,
  routes,
  handleClickMap,
  showMore,
  showOsmMap,
  addToOrder,
  admin,
}: TBrendMap) => {
  const defaultCenter = [-0.0010858992, -0.0008805681];
  const popup = useRef<HTMLDivElement>(null);
  const svgContainer = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);
  const [overlay, setOverlay] = useState<Overlay | null>(null);
  const [clusters, setClusters] = useState<VectorLayer<Cluster> | null>(null);
  const [path, setPath] = useState<VectorLayer<VectorSource<LineString>> | null>(null);
  const [cityInfo, setCityInfo] = useState<TSortedLocation | null>(null);
  const [pointDesignInfo, setPointDesignInfo] = useState<TSortedLocation | null>(null);
  const [pointReadyTourInfo, setPointReadyTourInfo] = useState<TSortedLocation | null>(null);
  const [initMap, setInitMap] = useState(false);

  const showDescLoc = (location: TLocation) => {
    if (!showMore) return;
    showMore(location);
  };

  const setPointer = (event: MapBrowserEvent<any>, newClusters: VectorLayer<Cluster>) => {
    if (!map) return;
    newClusters.getFeatures(event.pixel).then((features) => {
      map.getTargetElement().style.cursor = features[0] ? 'pointer' : '';
    });
  };

  const closeBalloon = () => {
    setCityInfo(null);
    setPointDesignInfo(null);
    setPointReadyTourInfo(null);
    if (overlay) {
      overlay.setPosition(undefined);
    }
  };

  const handleClickCluster = (event: MapBrowserEvent<any>, newClusters: VectorLayer<Cluster>) => {
    closeBalloon();
    if (admin || !overlay || !map) return;
    newClusters.getFeatures(event.pixel).then((features) => {
      if (features.length === 0) return;
      const clusterMembers: Feature[] = features[0].get('features');
      const infoLoc: TSortedLocation = clusterMembers[0].get('infoLoc');
      const extent = createEmpty();
      const coords = features[0].getGeometry()?.getExtent();
      if (clusterMembers.length > 1) {
        clusterMembers.forEach((feature) => {
          const newExtent = feature?.getGeometry()?.getExtent();
          if (newExtent) {
            extend(extent, newExtent);
          }
        });
        const view = map.getView();
        if (view.getZoom() !== view.getMaxZoom()) {
          view.fit(extent, { duration: 500, padding: [100, 50, 50, 50] });
        }
      } else if ('items' in infoLoc) {
        setCityInfo(infoLoc);
        overlay.setPosition(coords);
      } else if (showMore) {
        setPointDesignInfo(infoLoc);
        overlay.setPosition(coords);
      } else {
        setPointReadyTourInfo(infoLoc);
        overlay.setPosition(coords);
      }
    });
  };

  useEffect(() => {
    if (!map) return;
    closeBalloon();
    const newClusters = generateClusters(locations);
    if (clusters) {
      map.removeLayer(clusters);
    }
    setClusters(newClusters);
    map.getLayers().insertAt(1, newClusters);
    const handleSingleClick = (event: MapBrowserEvent<any>) => handleClickCluster(event, newClusters);
    const handlePointerMove = (event: MapBrowserEvent<any>) => setPointer(event, newClusters);
    if (!handleClickMap) {
      if (window.innerWidth > 768) {
        map.on('pointermove', handlePointerMove);
      }
      map.on('singleclick', handleSingleClick);
    }
    return () => {
      map.un('pointermove', handlePointerMove);
      map.un('singleclick', handleSingleClick);
    };
  }, [map, locations]);

  useEffect(() => {
    if (!map) return;
    if (path) map.removeLayer(path);
    if (!routes?.length) return;
    const zoom = map.getView().getZoom();
    const newPath = generatePath(routes, zoom);
    setPath(newPath);
    map.addLayer(newPath);
  }, [map, routes]);

  useEffect(() => {
    const svgContainerEl = svgContainer.current;
    if (!svgContainerEl) return;
    const width = 130;
    const height = 137;
    const svgResolution = 400 / width;
    svgContainerEl.style.width = width + 'px';
    svgContainerEl.style.height = height + 'px';
    svgContainerEl.style.transformOrigin = 'top left';
    svgContainerEl.className = 'svg-layer';
    const brendMapLayer = new Layer({
      render: function (frameState) {
        const scale = svgResolution / frameState.viewState.resolution;
        const center = frameState.viewState.center;
        const size = frameState.size;
        const cssTransform = composeCssTransform(
          size[0] / 2,
          size[1] / 2,
          scale,
          scale,
          frameState.viewState.rotation,
          -center[0] / svgResolution - width / 2,
          center[1] / svgResolution - height / 2
        );
        svgContainerEl.style.transform = cssTransform;
        svgContainerEl.style.position = 'absolute';
        return svgContainerEl;
      },
    });
    const overlayPopup = generateOverlay(popup.current);
    const map = new Map({
      target: 'brend-map',
      layers: [brendMapLayer],
      overlays: [overlayPopup],
      view: new View({
        enableRotation: false,
        center: transformCoords(center || defaultCenter),
        extent: [-200, -210, 200, 210],
        zoom: 21,
        maxZoom: 22,
      }),
    });
    setTimeout(() => {
      map.updateSize();
      setInitMap(true);
    }, 1000);
    setMap(map);
    setOverlay(overlayPopup);
    if (handleClickMap) {
      map.on('singleclick', handleClickMap);
    }
    return () => {
      if (handleClickMap) {
        map.un('singleclick', handleClickMap);
      }
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id='brend-map' style={{ width: '100%', height: '100%' }}></div>
      <div className='balloon' ref={popup}>
        <div className='balloon-wrapper'>
          {!!cityInfo && <CityBalloon loc={cityInfo} addToOrder={addToOrder} showOsmMap={showOsmMap} />}
          {!!pointDesignInfo && (
            <PointDesignBalloon loc={pointDesignInfo} addToOrder={addToOrder} showDescLoc={showDescLoc} />
          )}
          {!!pointReadyTourInfo && <PointReadyTourBalloon loc={pointReadyTourInfo} />}
          <div className='balloon__close'>
            <div className='balloon__close-button' onClick={closeBalloon}></div>
          </div>
        </div>
        <div className='balloon-arrow'>
          <BalloonArrow className='balloon-arrow__svg' />
        </div>
      </div>
      <div style={{ opacity: initMap ? '1' : '0' }} ref={svgContainer}>
        {map && <Svg />}
      </div>
    </div>
  );
};

export default BrendMap;
