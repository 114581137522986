import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Notification from 'shared/lib/notification';
import { clearOrder, resetStore } from 'manageStore/designer/designer.slice';
import { setIsOpen } from 'manageStore/common/common.slice';
import { selectIsAuth, selectUserData, setAuth, setUserData } from 'manageStore/user/userSlice';
import RequestService from 'services/request.service';
import { BaseInput } from 'shared/ui/BaseInput/BaseInput';
import { BaseTextarea } from 'shared/ui/BaseTextarea/BaseTextArea';
import { TTourType } from 'shared/types/types';
import { Link, useHistory } from 'react-router-dom';
import { handleError } from 'http/handleError';

type TTourApplicationFormProps = {
  handleSuccess: () => void;
  onClose: () => void;
  tourType: TTourType;
  tour: Record<string, unknown>;
};

export const TourApplicationForm = ({ handleSuccess, onClose, tour, tourType }: TTourApplicationFormProps) => {
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const isAuth = useSelector(selectIsAuth);
  const history = useHistory();

  const createRequestAndRedirect = async () => {
    if (isAuth && userData && Object.keys(userData).length) {
      const request = RequestService.createAuthRequest({
        tour,
        tourType,
        comment: '',
        userId: userData.id,
      });
      await Notification.promise(request);
      history.push('/account');
    }
  };

  const formik = useFormik({
    initialValues: {
      name: isAuth ? 'profile' : '',
      email: isAuth ? 'somemail@m.ru' : '',
      comment: '',
      policy: false,
      personal: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(3, 'Длина должна быть не менее 3 символов').required('Заполните поле'),
      email: Yup.string().email('Некорректный Email-адрес').required('Заполните поле'),
      policy: Yup.boolean().oneOf([true], 'Политика должна быть принята'),
      personal: Yup.boolean().oneOf([true], 'Соглашение должно быть принято'),
    }),
    onSubmit: async (values) => {
      const obj = {
        tour,
        tourType,
        comment: values.comment,
      };
      try {
        if (isAuth && userData) {
          const request = RequestService.createAuthRequest({ ...obj, userId: userData.id });
          await Notification.promise(request);
        } else {
          const request = RequestService.createRequest({
            ...obj,
            login: values.name,
            email: values.email,
            phone: '',
          });

          const { data } = await Notification.promise(request, true);
          Notification.info('Ваш личный кабинет создан, выполняется вход', 10000);

          localStorage.setItem('token', data.accessToken);
          dispatch(setUserData(data.user));
          dispatch(setAuth(true));
          setTimeout(handleSuccess, 10000);
        }
        dispatch(clearOrder());
        //handleSuccess();
        setTimeout(handleSuccess, 20000);
      } catch (e) {
        const message = handleError(e);
        if (message.includes(`${values.email} уже существует`)) {
          Notification.info('Пожалуйста авторизуйтесь!', 20000);
          dispatch(setIsOpen(true));
        }
      }
      formik.resetForm();
      tourType === 'Designer' && dispatch(resetStore());
      tourType === 'Ready' && onClose();
    },
    enableReinitialize: true,
  });

  return (
    <form className='tour-application-form' onSubmit={formik.handleSubmit}>
      {isAuth ? (
        <div className='tour-application-form__buttons'>
          <button className='tour-application-form__submit' onClick={createRequestAndRedirect} type='button'>
            Да
          </button>
          <button className='tour-application-form__submit' onClick={onClose}>
            Нет
          </button>
        </div>
      ) : (
        <>
          <BaseInput
            showError={!!(formik.touched.name && formik.errors.name)}
            errorText={formik.errors.name}
            placeholder='Ваше имя'
            id='name'
            type='text'
            {...formik.getFieldProps('name')}
          />
          <BaseInput
            showError={!!(formik.touched.email && formik.errors.email)}
            errorText={formik.errors.email}
            placeholder='E-mail'
            id='email'
            type='email'
            {...formik.getFieldProps('email')}
          />
          <BaseTextarea
            placeholder='Дополнительные пожелания'
            id='comment'
            maxLength={500}
            {...formik.getFieldProps('comment')}
          />
          <label className='registration-label-policy'>
            <input className='registration-policy' id='policy' type='checkbox' {...formik.getFieldProps('policy')} />
            <span></span>
            <p>
              Согласен с{' '}
              <Link className='registration-policy-link' to='/privacy'>
                политикой конфиденциальности
              </Link>{' '}
              и{' '}
              <Link className='registration-policy-link' to='/useragreement'>
                пользовательским соглашением
              </Link>
            </p>
          </label>
          <p className='input-group__error'>
            {formik.touched.policy && formik.errors.policy ? formik.errors.policy : ''}
          </p>
          <label className='registration-label-policy'>
            <input
              className='registration-policy'
              id='personal'
              type='checkbox'
              {...formik.getFieldProps('personal')}
            />
            <span></span>
            <p>
              Согласен на{' '}
              <a className='registration-policy-link' href='/docs/personal_consent.pdf' target='_blank'>
                обработку персональных данных
              </a>
            </p>
          </label>
          <p className='input-group__error'>
            {formik.touched.personal && formik.errors.personal ? formik.errors.personal : ''}
          </p>
          <button className='tour-application-form__submit' type='submit'>
            Оставить заявку
          </button>
        </>
      )}
    </form>
  );
};
