import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import RegForm from 'components/RegForm/RegForm';
import Portal from '../components/Portal/Portal';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import logo from '../assets/images/header/logo_top.svg';
import { useDispatch } from 'react-redux';

const RegistrationPage = () => {
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.user.userData);
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const history = useHistory();

  const handelPortal = useCallback(() => {
    history.push('/');
  }, [dispatch]);

  useEffect(() => {
    if (isAuth && userData && Object.keys(userData).length) history.push('/account');
  }, [isAuth, userData, history]);

  return (
    <>
      <Helmet>
        <title>Регистрация - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <section className='content login-page-bg'>
        <div className='login-page__background'>
          <div className='login-page__background__image'>
            <Link className='login-page__background__logo' to='/'>
              <img className='logo' src={logo} alt='Туркластер Арктический' />
            </Link>
            <div className='login-page__background__label'>ПУТОРАНА</div>
          </div>
        </div>
        <Portal
          className='registration-portal'
          classHeader='registration-portal__header'
          isOpen={true}
          onClose={handelPortal}
          title='Регистрация'
        >
          <RegForm />
        </Portal>
      </section>
    </>
  );
};

export default RegistrationPage;
