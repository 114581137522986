import React, { useEffect, useState } from 'react';
import Layout from '../../widgets/layout/ui';
import ApplicationList from '../../widgets/application-list/ui';
import Onboarding from '../../features/onboarding/ui';
import PageHead from '../../entities/page-head/index';
import ContainerContent from '../../entities/container';
import { TUserType } from '../../../shared/types/types';
import { useAsync, useLocalStorage } from 'react-use';
import s from './index.module.scss';
import { REQUEST_STATUSSES } from '../../../shared/constants/const';
import { useAppSelector } from '../../../shared/hooks/useAppSelector';
import { useHistory } from 'react-router-dom';
import RequestService from '../../../services/request.service';
import Notification from '../../../shared/lib/notification';
import useCheckRegister from '../../shared/hooks/useCheckRegister';
import NoRequests from '../../features/no-requests';
import PopperCustom from 'newProject/entities/popper';
import Loading from 'components/Loading/Loading';
import clsx from 'clsx';

interface RequestsPageNewProps {
  type: TUserType;
  navs: any;
  mobileNavs: any;
}

export const RequestsTypes = [
  {
    name: 'ДОСТУПНЫЕ ЗАЯВКИ',
    value: 'Новый',
    status: 'NEW',
  },
  {
    name: 'Текущие заявки',
    value: 'В работе',
    status: 'PROCESSING',
  },
  {
    name: 'Завершенные заявки',
    value: 'Заверешена',
    status: 'DONE',
  },
  {
    name: 'Непринятые заявки',
    value: 'В рассмотрении',
    status: 'DENIED',
  },
];

const RequestsPageNew = ({ type, ...props }: RequestsPageNewProps) => {
  useCheckRegister(type);
  const userData = useAppSelector((state) => state.user.userData);
  const history = useHistory();

  const [isOpenOnboarding, setOpenOnboarding] = useLocalStorage('is_open_onboarding_tourist', true);

  const { value: requests, loading } = useAsync(async () => {
    try {
      const requests = await Promise.all(
        RequestsTypes.map(async (type) => {
          const reqData = {
            status: type.status,
            id: userData?.id ?? 0,
            type: userData?.type.toLowerCase() ?? '',
          };
          const data = await RequestService.fetchRequests(reqData);
          return {
            ...type,
            requests: data.data,
          };
        })
      );
      return requests;
    } catch (error: any) {
      Notification.error(error?.response?.data?.message || 'Произошла ошибка!');
    }
  });
  const noRequests = requests?.every((e) => !e.requests.length);

  return (
    <Layout>
      {isOpenOnboarding && (
        <Onboarding
          onClose={() => setOpenOnboarding(false)}
          slides={[
            {
              title: 'Личный кабинет',
              description:
                'Добро пожаловать в ваш личный кабинет! Здесь доступен широкий спектр возможностей для создания удивительных путешествий!',
            },
            {
              title: 'Заявки',
              description:
                'Выбери свое путешествие! После подтверждения заявки вы сможете ее отслеживать и общаться с организатором путешествия',
            },
            {
              title: 'Отзывы',
              description: 'Поделись своими впечатлениями с организатором, отзывы помогут стать лучше!',
            },
            // todo: Вернуть когда вернем Избранные маршруты
            // {
            //   title: 'Избранные маршруты',
            //   description:
            //     'В конструкторе путешествий есть возможность создать свое собственное путешествие, здесь будут отображаться ваши сохраненные маршруты',
            // },
            {
              title: 'Вперед к открытиям!',
              description:
                'Начни свое путешествие прямо сейчас, переходи на страницу «Туры и экскурсии» или создай свой собственный маршрут «Конструктор путешествий» + кнопка остаться в личном кабинете',
            },
          ]}
        />
      )}
      <PageHead>Мои заявки</PageHead>
      <div className={clsx('md-max:bg-white relative')}>
        {requests && !noRequests ? (
          <ContainerContent className={s.container}>
            <ApplicationList requests={requests} />
          </ContainerContent>
        ) : loading ? (
          <div className={s.full}>
            <Loading />
          </div>
        ) : (
          <div className={s.full}>
            <NoRequests />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default RequestsPageNew;
