import { useOsmMap } from 'widgets/shared/Maps/hooks/useOsmMap';
import { useAddPoints } from 'widgets/shared/Maps/hooks/useAddPoints';
import { OsmMap } from 'widgets/shared/Maps/lib/OsmMap';
import { getOsmViewOptions } from 'widgets/shared/Maps/helpers/getOsmViewOptions';
import { useMemo } from 'react';

type TTravelGuideItemOsmMapProps = {
  location: number[];
};

export const TravelGuideItemOsmMap = ({ location }: TTravelGuideItemOsmMapProps) => {
  const viewOptions = getOsmViewOptions({ zoom: 12, center: location });

  const clusterData = useMemo(() => [{ coords: location }], location);

  const [map] = useOsmMap('osm-map', viewOptions, { controls: [], interactions: [] });
  useAddPoints(map, clusterData, 'travelGuide');

  return <OsmMap map={map} />;
};
